import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  setEntries: any;
  entries: string;
};

const RowToDisplay = ({ setEntries, entries }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  return (
    <FormControl
      sx={{
        width: '8em',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: `${theme.palette.primary.main} !important`,
        },
        '& .MuiInputLabel-root': {
          color: `${theme.palette.primary.main} !important`,
        },
      }}
    >
      <InputLabel
        id="TranslationHistory-no-entries-label"
        className="bg-white"
      >
        {t('history.noOfEntries')}
      </InputLabel>
      <Select
        labelId="TranslationHistory-no-entries-label"
        value={entries}
        onChange={(event) => setEntries(event?.target.value)}
      >
        {[10, 20, 60, 100].map((value) => (
          <MenuItem
            key={value}
            value={value}
            sx={{
              '&.Mui-selected': {
                backgroundColor: `${theme.palette.secondary.main} !important`,
              },
            }}
          >
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RowToDisplay;
