import { LoadingButton } from '@mui/lab';
import { Typography, Grid, useTheme } from '@mui/material';
import { isEmpty, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PasswordInput from 'src/coreUI/passwordInput/PasswordInput';
import { setNewPasswordAction } from 'src/redux/supabase/actions';
import { supabaseSelector } from 'src/redux/supabase/selector';

type Props = {
  style?: object;
};

const ResetPasswordForm = ({ style = {} }: Props) => {
  const theme = useTheme();
  const distpatch: any = useDispatch();
  const [inputValue, setInputValue] = useState<{
    password: string;
    confirmPassword: string;
  }>({
    password: '',
    confirmPassword: '',
  });
  const { confirmPassword, password } = inputValue;

  const { t } = useTranslation('common');

  const loading = useSelector(
    (state: any) => supabaseSelector(state).loading,
    shallowEqual
  );

  const session = useSelector(
    (state: any) => supabaseSelector(state).session,
    shallowEqual
  );

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInputValue({ ...inputValue, [e?.target.name]: e?.target.value });
  };
  const handleConfirm = async () => {
    await distpatch(setNewPasswordAction(password, session.user?.email));
  };

  useEffect(() => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      localStorage.removeItem('token');
    });
    return () => {
      window.removeEventListener('beforeunload', (event) => {
        event.preventDefault();
      });
    };
  });

  return (
    <Grid
      item
      container
      sm={4}
      xs={12}
      sx={{ m: 'auto', marginTop: '8em', ...style }}
      spacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: 'bold' }}
        >
          {t('setNewPassword.newPasswordPrompt')}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography
          variant="h5"
          gutterBottom
        >
          {session.user?.email}
        </Typography>
      </Grid>
      <Grid
        item
        container
        sm={10}
        xs={12}
        spacing={3}
        m={'auto'}
      >
        <Grid
          item
          xs={12}
        >
          <PasswordInput
            value={password}
            handleChange={handleChange}
            label={t('signupForm.password')}
            name={'password'}
            style={{ width: '100%', m: 0 }}
            size="small"
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <PasswordInput
            value={confirmPassword}
            handleChange={handleChange}
            label={t('signupForm.passwordConfirm')}
            name={'confirmPassword'}
            style={{ width: '100%', m: 0 }}
            size="small"
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <LoadingButton
            loadingIndicator={t('translationbutton.loading')}
            aria-disabled={
              isEmpty(password) || !isEqual(password, confirmPassword)
            }
            loading={loading}
            fullWidth
            variant="contained"
            disabled={isEmpty(password) || !isEqual(password, confirmPassword)}
            onClick={handleConfirm}
            sx={{
              textTransform: 'capitalize',
              color: theme.palette.secondary.main,
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
              },
              '&:disabled': {
                color: theme.palette.secondary.main,
                backgroundColor: theme.palette.primary.main,
              },
            }}
            disableElevation
          >
            {t('setNewPassword.confirm')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordForm;
