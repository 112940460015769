import React from 'react';
import { Grid, useTheme } from '@mui/material';
import ImagePageListComp from '../imageSearchComp/ImagePageListComp';
import { ImageGenerationWrapperProps } from './imageGeneration.types';
import ImagePageSearchInputComp from '../../coreUI/searchComp/ImagePageSearchInputComp';
import CardCover from '@mui/joy/CardCover';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useTranslation } from 'react-i18next';

const ImageGenerationComp = ({
  searchUploadedImages,
  generatedImages,
  loading,
  langWords,
  tempImageList,
  feedbackImageUpload,
  selectedOutputNode,
  uploadedImageList,
  deletedImgId,
  handleInsertImage,
  isExpanded,
  imageGenerationText,
  setImageGenerationText,
}: ImageGenerationWrapperProps) => {
  const { t } = useTranslation(['easy_language', 'common', 'A11y'], {
    nsMode: 'fallback',
  });
  const theme = useTheme();
  const handleSubmitPrompt = (value: string) => {
    searchUploadedImages(value);
  };

  return (
    <Grid
      item
      container
      xs={12}
      spacing={2}
    >
      {loading && (
        <CardCover
          sx={{
            background: theme.palette.general.blockingOverlay,
            zIndex: 2,
          }}
        >
          <Box sx={{ width: '100%', height: '100%' }}>
            <LinearProgress
              sx={{
                position: 'absolute',
                width: '60%',
                background: theme.palette.secondary.main,
                '& .MuiLinearProgress-bar': {
                  background: theme.palette.primary.main,
                },
              }}
              aria-label={t('image.generating')}
            />
          </Box>
        </CardCover>
      )}
      {isExpanded && (
        <Grid
          item
          xs={12}
        >
          <ImagePageSearchInputComp
            handleSubmit={handleSubmitPrompt}
            loading={loading}
            placeholder={langWords.searchImage}
            searchWord={langWords.searchWord}
            text={imageGenerationText}
            setText={setImageGenerationText}
            isVisible={isExpanded}
          />
        </Grid>
      )}

      <Grid
        item
        xs={12}
      >
        {!generatedImages || Object.values(generatedImages).length === 0 ? (
          <div style={{ color: theme.palette.general.white }}>
            {langWords.noImage}
          </div>
        ) : (
          <ImagePageListComp
            imageItem={{
              id: 0,
              name: 'Sample Image',
              owner_email: 'owner@example.com',
              source_type: 2,
              is_owner: false,
              website: null,
              is_public: false,
              license: null,
              images: generatedImages?.map((gi) => ({
                uuid: Math.random().toString(),
                similarity: 0.85,
                feedback: 1,
                content_base64: gi,
              })),
            }}
            key={0}
            downloadUploadedImage={(_) => {}}
            feedbackImageUpload={feedbackImageUpload}
            deleteUploadedImage={(_) => {}}
            handleInsertImage={handleInsertImage}
            selectedOutputNode={selectedOutputNode}
            langWords={langWords}
            deletedImgId={deletedImgId}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ImageGenerationComp;
