import React from 'react';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { AccordionDetails, styled, useTheme } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  // borderRadius: '5px',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    {...props}
    expandIcon={props.expandIcon}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
}));

type Props = {
  id?: string;
  accordionSummary: React.ReactNode | string;
  summaryStyle?: object;
  accordionDetails: React.ReactNode | string;
  detailsStyle?: object;
  expandAcc?: boolean;
  handleChange?: () => void;
  accordionStyle?: object;
  disabled?: boolean;
  expandIcon?: React.ReactNode;
  firstItem?: boolean;
  lastItem?: boolean;
  onlyItem?: boolean;
};
const GlossaryItemView = ({
  id = 'panel1-header',
  accordionSummary,
  summaryStyle = {},
  accordionDetails,
  detailsStyle = {},
  expandAcc,
  handleChange,
  accordionStyle = {},
  disabled,
  expandIcon,
  firstItem = false,
  lastItem = false,
  onlyItem = false,
}: Props) => {
  const theme = useTheme();
  return (
    <Accordion
      expanded={expandAcc}
      onChange={handleChange}
      disabled={disabled}
      disableGutters
      square={false}
      sx={{
        boxShadow: 'none',
        '& .Mui-expanded': {
          transform: 'rotate(0deg)',
        },
        borderRadius: firstItem
          ? '5px 5px 0px 0px !important'
          : lastItem
            ? '0px 0px 5px 5px !important'
            : onlyItem
              ? '5px 5px 5px 5px !important'
              : '0 0 0 0 !important',
        ...accordionStyle,
      }}
    >
      <AccordionSummary
        expandIcon={expandIcon}
        aria-controls={id}
        aria-label={id}
        id={'accordinSummary'}
        sx={{
          // height: '37px',
          minHeight: '37px',
          backgroundColor: 'rgba(0, 0, 0, .03)',
          color: theme.palette.primary.main,
          cursor: 'auto',
          '& .MuiAccordionSummary-content': {
            m: 0,
          },
          '& .MuiIconButton-root': {
            pr: '5px',
          },
          background: theme.palette.general.white,
          borderRadius: firstItem
            ? '5px 5px 0px 0px !important'
            : lastItem
              ? '0px 0px 5px 5px !important'
              : onlyItem
                ? '5px 5px 5px 5px !important'
                : '0 0 0 0 !important',
          p: '0px 11px 0 9px',
          ...summaryStyle,
        }}
      >
        {accordionSummary}
      </AccordionSummary>
      <AccordionDetails
        sx={{ ...detailsStyle, background: theme.palette.secondary.main }}
        id={'accordinDetails'}
      >
        {accordionDetails}
      </AccordionDetails>
    </Accordion>
  );
};

export default GlossaryItemView;
