import React from 'react';
import { Grid } from '@mui/material';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ActionButton from './ActionButton';
import { isEmpty } from 'lodash';
import SumaryText from './SummaryText';
import { ResizableBox, ResizeCallbackData } from 'react-resizable';
import './summary.css';
import { Props } from './summary.types';
import LoadingHOC from '../../coreUI/loadingHOC/LoadingHOC';

const SummaryComp = ({
  inputTextValue,
  getSummary,
  resetSummary,
  transjob,
  historyJob,
  loading,
  summarySelector,
  localizedTexts,
  updateSummary,
  separatorStyle,
}: Props) => {
  const [heightInput, setInputHeight] = useState<number>(300);

  const handleResizeUpdate = (e: SyntheticEvent, data: ResizeCallbackData) => {
    setInputHeight(data.node.offsetParent?.clientHeight ?? 0);
  };

  const getTheSummary = useCallback(
    (useCache = true) => {
      // const jobId = localStorage.getItem('jobId');
      if (!isEmpty(inputTextValue)) {
        getSummary({
          job_id: Number(
            transjob.jobId !== '-1' ? transjob.jobId : historyJob?.id
          ),
          input_text: inputTextValue,
          input_text_type: 'html',
          separator_style: separatorStyle || 'none',
          use_cache: useCache,
        });
      } else {
        resetSummary({ summary: null });
      }
    },
    [getSummary, historyJob?.id, inputTextValue, resetSummary, transjob.jobId]
  );

  useEffect(() => {
    if ((historyJob || transjob.jobId !== '-1') && !isEmpty(inputTextValue)) {
      getTheSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyJob, transjob.jobId, inputTextValue]);

  const summaryText = useMemo(
    () => (
      <Grid
        item
        xs={12}
      >
        <SumaryText
          heightInput={heightInput}
          summarySelector={summarySelector}
          noSummaryText={localizedTexts.noSummaryText}
        />
      </Grid>
    ),
    [heightInput, localizedTexts.noSummaryText, summarySelector]
  );
  return (
    <Grid
      item
      container
      xs={12}
      id="summary-box"
    >
      <LoadingHOC loading={loading || false}>
        {summarySelector ? (
          <ResizableBox
            height={337}
            minConstraints={[300, 337]}
            onResize={handleResizeUpdate}
            axis={'y'}
            width={document.getElementById('summary-box')?.clientWidth || 300}
          >
            <Grid
              item
              container
              xs={12}
              sx={{
                border: `1px solid #dee2e6`,
                borderRadius: '5px',
              }}
            >
              {summaryText}
              <Grid
                item
                xs={12}
              >
                <ActionButton
                  getTheSummary={getTheSummary}
                  regenerateText={localizedTexts.regenerateText}
                  likeText={localizedTexts.likeText}
                  dislikeText={localizedTexts.dislikeText}
                  regenerate_summary_aria_label={
                    localizedTexts.regenerate_summary_aria_label
                  }
                  like_summary_feedback_aria_label={
                    localizedTexts.like_summary_feedback_aria_label
                  }
                  dislike_summary_feedback_aria_label={
                    localizedTexts.dislike_summary_feedback_aria_label
                  }
                  summarySelector={summarySelector}
                  updateSummary={updateSummary}
                />
              </Grid>
            </Grid>
          </ResizableBox>
        ) : (
          <Grid
            item
            container
            xs={12}
            sx={{
              border: `1px solid #dee2e6`,
              borderRadius: '5px',
            }}
          >
            {summaryText}
          </Grid>
        )}
      </LoadingHOC>
    </Grid>
  );
};

export default SummaryComp;
