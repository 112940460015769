import 'react-app-polyfill/ie11';
import 'core-js';
import React from 'react';
import './custom.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import WrapperIndexComp from './components/wrapperIndexComp/WrapperIndexComp';
import './config/sentryConfig';
import i18next from './i18n/i18n';
import { I18nextProvider } from 'react-i18next';
import store, { persistor } from './redux/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import reportAccessibility from './utils/reportAccessibility';
import { PersistGate } from 'redux-persist/integration/react';
import { createRoot } from 'react-dom/client';

declare global {
  interface Window {
    store: typeof store;
  }
}

const root = createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persistor}
      >
        <BrowserRouter>
          <WrapperIndexComp>
            <I18nextProvider i18n={i18next}>
              <App />
            </I18nextProvider>
          </WrapperIndexComp>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
reportAccessibility(React);
