import { useEffect, useState } from 'react';
import { Gift, InfoSquare } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import InfoComponent from '../../widgets/InfoComponent';
import ToggleSwitch from '../../widgets/ToggleSwitch';
import LoadingBanner from '../../coreUI/loadingBanner/LoadingBanner';
import HelpButton from './HelpButton';
import { offlineProducts } from './offlineProducts';
import ProductCard from './ProductCard';
import styles from './SelectionPage.module.css';
import { Container, Grid, useTheme } from '@mui/material';
import {
  Product,
  ProductSelection,
  SetToggleLicencePeriodProps,
} from 'src/types/product.types';

type Props = {
  cookies: any;
};
const SelectionPage = (props: Props) => {
  const history = useNavigate();
  const theme = useTheme();
  const cookies = props.cookies;

  const [loading, setLoading] = useState(true);
  const [licencePeriod, setLicencePeriod] = useState('Monatlich');
  const [checked, setChecked] = useState(false);

  const [allProducts, setAllProducts] = useState<Product[]>([]);

  const handleProductSelection = (product: ProductSelection) => {
    product = { ...product, licencePeriod: licencePeriod };
    cookies.set('selectedProduct', product, { path: '/' });
    history('/checkout');
  };

  useEffect(() => {
    setLoading(false);
    setAllProducts(offlineProducts);
  }, []);

  const toggleLicencePeriod = () => {
    if (licencePeriod === 'Monatlich') {
      setLicencePeriod('Jährlich');
      setChecked(true);
    } else {
      setLicencePeriod('Monatlich');
      setChecked(false);
    }
  };

  const setToggleLicencePeriod: SetToggleLicencePeriodProps = (checked) => {
    setChecked(checked);
    if (checked) {
      setLicencePeriod('Jährlich');
    } else {
      setLicencePeriod('Monatlich');
    }
  };
  return (
    <Container className={'mb-5 mt-10' + styles['container']}>
      <Grid
        item
        xs={12}
        className="mt-5"
        textAlign={'center'}
        sx={{ color: theme.palette.primary.main }}
      >
        <h1>Leichte Sprache. Leicht gemacht.</h1>
        <div className={styles['header-infodiv']}>
          <InfoSquare className={styles['header-infoicon']} />
          <span>
            Für alle Pakete gilt: <b>14 Tage kostenfrei</b> testen - damit Sie
            sich selbst von SUMM überzeugen können!
          </span>
        </div>
      </Grid>

      {loading ? (
        <LoadingBanner />
      ) : (
        <Grid
          item
          container
          spacing={4}
        >
          <Grid
            item
            xs={12}
            textAlign={'center'}
          >
            <div className={styles['header-togglediv']}>
              <div style={{ flex: 1 }}></div>

              <ToggleSwitch
                leftLabel={'monatlich'}
                rightLabel={'jährlich'}
                rightStyle={{
                  fontWeight: 'bold',
                }}
                checked={checked}
                onClick={toggleLicencePeriod}
              />
              <div
                className={styles['toggle-gift-div']}
                onClick={() => setToggleLicencePeriod(true)}
              >
                <div
                  className={
                    styles['toggle-gift'] + ' ' + styles['toggle-gift-icon']
                  }
                >
                  <Gift className={styles['toggle-gifticon']} />
                </div>
                <div
                  className={
                    styles['toggle-gift'] + ' ' + styles['toggle-gift-text']
                  }
                >
                  <span>1 Monat gratis!</span>
                </div>
              </div>
            </div>
          </Grid>
          <Grid
            item
            container
            xs={12}
            textAlign={'center'}
            spacing={4}
          >
            <Grid
              item
              xs={12}
              className={styles['info-div']}
            >
              <InfoComponent
                heading="Diese Pakete sind erhältlich für freiberufliche Leichte Sprache Übersetzer:innen und Leichte Sprache Büros von sozialen Trägern, eingetragenen Vereinen und gGmbHs."
                iconStyle={{
                  minWidth: '27px',
                }}
                headerStyle={{
                  fontSize: '12px',
                  lineHeight: '1.1',
                }}
                body={
                  <>
                    <span
                      style={{
                        fontSize: '12px',
                        margin: '0px',
                        lineHeight: '1.1',
                      }}
                    >
                      Grund dafür ist, dass sie speziell für diese Zielgruppen
                      konzipiert sind. Sie erfüllen die oben genannten Kriterien
                      aktuell nicht? Sprechen Sie uns für ein individuelles
                      Angebot{' '}
                      <b>
                        <a href="mailto:team@summ-ai.com">hier</a>
                      </b>{' '}
                      an.
                    </span>
                  </>
                }
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              spacing={4}
            >
              {allProducts?.map((product) => (
                <Grid
                  item
                  xs={12}
                  md={4}
                  className={styles['productcard-col']}
                  key={product.id}
                >
                  <ProductCard
                    product={product}
                    handleProductSelection={handleProductSelection}
                    licencePeriod={licencePeriod}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              className="d-flex flex-column-reverse"
              textAlign={'right'}
            >
              <HelpButton />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default SelectionPage;
