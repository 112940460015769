import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';
import rootreducers from './reducers';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['appSetting'],
};

// Create a persisted reducer using Redux-persist
const persistedReducer = persistReducer(persistConfig, rootreducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      sentry: sentryReduxEnhancer,
      serializableCheck: false,
    }),
});

// Create a persistor to persist the Redux store
export const persistor = persistStore(store);

export default store;
