import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Badge, Tooltip, Typography, useTheme } from '@mui/material';
// import TooltipComp from '../tooltipsComp/TooltipComp';

type Props = {
  menuItem: {
    id: string | number;
    value: number | string;
    text: string;
    desc?: string;
  }[];
  label?: string;
  id?: string;
  handleChange: (event: SelectChangeEvent<string>) => void;
  handleMouseUp?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  value: string | undefined;
  disabled?: boolean;
  toolTipsTitle?: string | undefined;
  toolTipPlacement?:
    | 'bottom'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
    | undefined;
  style?: object;
  formStyle?: object;
  name?: string;
  error?: boolean;
  require?: boolean;
  labelSize?: 'normal' | 'small' | undefined;
  selectSize?: 'small' | 'medium' | undefined;
  badge?: boolean;
  handleOnClick?: () => void;
};

const MaterialSelectComp = ({
  menuItem,
  label,
  id = 'demo-select-small-label',
  handleChange,
  value,
  disabled = false,
  toolTipsTitle = undefined,
  toolTipPlacement = 'bottom',
  style,
  formStyle,
  name,
  error,
  require,
  handleMouseUp,
  labelSize = 'small',
  selectSize = 'small',
  badge = true,
  handleOnClick,
}: Props) => {
  const theme = useTheme();
  return (
    <Tooltip
      title={toolTipsTitle}
      placement={toolTipPlacement}
    >
      <FormControl
        sx={{
          m: 1,
          minWidth: 120,
          width: '37%',
          // mt: '2em',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.primary.main} !important`,
          },
          '& .MuiInputLabel-root': {
            color: `${theme.palette.primary.main} !important`,
          },
          ...formStyle,
        }}
        size="small"
        error={error}
        required={require}
        onClick={handleOnClick}
      >
        <Badge
          color="error"
          variant="dot"
          invisible={badge}
        >
          <InputLabel
            id={`${id}-label`}
            size={labelSize}
          >
            {label}
          </InputLabel>

          <Select
            labelId={`${id}-label`}
            id={id}
            value={value}
            label={label}
            onChange={handleChange}
            disabled={disabled}
            sx={style}
            name={name}
            onMouseUpCapture={handleMouseUp}
            size={selectSize}
          >
            {menuItem.map(({ id, value, text, desc }) => (
              <MenuItem
                value={value}
                key={id}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: `${theme.palette.secondary.main} !important`,
                  },
                }}
              >
                <Tooltip
                  title={desc}
                  arrow
                  placement="left"
                >
                  <Typography variant="body2">{text}</Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </Badge>
      </FormControl>
    </Tooltip>
  );
};

export default MaterialSelectComp;
