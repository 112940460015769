import { Theme, ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

export type general = {
  silverBlue: string;
  overlay: string;
  slateBlue: string;
  slateBlueOverlay: string;
  white: string;
  paleBlue: string;
  lightSteelBlue: string;
  cyanBlue: string;
  lightGray: string;
  silverGray: string;
  lightBlue: string;
  pattensBlue: string;
  teal: string;
  slateGray: string;
  grayishBlue: string;
  mediumLightGray: string;
  goldenYellow: string;
  blockingOverlay: string;
};
declare module '@mui/material/styles' {
  interface Palette extends Theme {
    general: general;
  }

  interface PaletteOptions extends ThemeOptions {
    general?: general;
  }
}

export default createTheme({
  palette: {
    primary: {
      main: '#232A35',
    },
    secondary: {
      main: '#E8ECEE',
    },
    error: {
      main: '#B32A2A',
    },
    general: {
      silverBlue: '#f8f9fa',
      overlay: 'rgb(0 0 0 / 9%)',
      slateBlue: '#54758e',
      slateBlueOverlay: 'rgb(83 105 138 / 20%)',
      white: '#ffffff',
      paleBlue: '#e9ecef',
      lightSteelBlue: '#ced4da',
      cyanBlue: '#8997A1',
      silverGray: '#b9b9b9',
      lightGray: '#f5f5f5',
      lightBlue: '#dee2e6',
      pattensBlue: '#E3E9ED',
      teal: '#4a7690',
      slateGray: '#6c757d',
      grayishBlue: '#656976',
      mediumLightGray: '#C4C4C4',
      goldenYellow: '#EDC347',
      blockingOverlay: 'rgb(83 105 138 / 60%)',
    },
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: '#232A35',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: 'revert',
  },
});
