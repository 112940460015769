import { useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import styles from '../TextAreaField.module.css';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
import { UserSelector } from 'src/redux/user/selector';
import TextFormatRoundedIcon from '@mui/icons-material/TextFormatRounded';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import Tooltip from '@mui/material/Tooltip';

type Props = {
  switchInput: (value: boolean) => void;
  fileInput: boolean;
};

const InputTypeChangeButton = ({ switchInput, fileInput }: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  const theme = useTheme();
  const hasGroup = useSelector(
    (state: any) => UserSelector(state).hasGroup,
    shallowEqual
  );
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        padding: matchesSM ? 'auto' : '10px 3%',
      }}
    >
      <Grid
        item
        xs="auto"
      >
        <Tooltip id={"textButton"} title={t('input.inputTextButton')}>
          <Button
            onClick={() => switchInput(false)}
            className={`${styles['sourceButton']} ${
              !hasGroup ? '' : fileInput ? '' : styles['active']
            }`}
            disabled={!hasGroup}
            sx={{
              textTransform: 'capitalize',
              borderBottom: '4px solid #fff',
            }}
          >
            <div>
              <TextFormatRoundedIcon className={styles['sourceButtonIcon']} />
            </div>
          </Button>
        </Tooltip>
      </Grid>
      <Grid
        item
        xs="auto"
      >
        <Tooltip id={"textButton"} title={t('input.inputFilesButton')}>
          <Button
            onClick={() => switchInput(true)}
            className={`${styles['sourceButton']} ${
              !hasGroup ? '' : fileInput ? styles['active'] : ''
            }`}
            disabled={!hasGroup}
            style={{
              color: theme.palette.primary.main,
              borderBottom: '4px solid #fff',
            }}
          >
            <div style={{ display: 'flex'}}>
              <DescriptionOutlinedIcon
                style={{ fontSize: '1.3rem' }}
                className={styles['sourceButtonIcon']}
              />
            </div>
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default InputTypeChangeButton;
