import synonymsService from 'src/services/synonyms.service';
import { appMessageHandling } from '../../utils/errorHandler';
import {
  EMPTY_STATE_VALUE,
  GET_SYNONYMS,
  LOADING,
  STOP_LOADING,
} from './types';
import { SynonymsReducer } from './selector';
import { AxiosResponse } from 'axios';

export const getSynonymsData =
  (originalRange: string) => async (dispatch: any) => {
    try {
      dispatch(loading());
      const res: AxiosResponse<any> = await synonymsService.getSynonymsAPI(
        originalRange
      );
      dispatch({
        type: GET_SYNONYMS,
        payload: res.data.finalSynonymList,
      });
    } catch (e: any) {
      return appMessageHandling(dispatch, e?.response?.data.detail, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

export const loading = () => (dispatch: any) => {
  return dispatch({
    type: LOADING,
  });
};

export const stopLoading = () => (dispatch: any) => {
  return dispatch({
    type: STOP_LOADING,
  });
};

export const emptyStateValueSynonyms =
  (value: Partial<SynonymsReducer>) => (dispatch: any) => {
    return dispatch({
      type: EMPTY_STATE_VALUE,
      payload: value,
    });
  };
