import imageUploadService from 'src/services/imageUpload.service';
import { appMessageHandling } from '../../utils/errorHandler';
import {
  // GET_ALL_UPLOADED_IMAGE,
  GENERATION_LOADING,
  SEARCH_LOADING,
  SEARCH_UPLOADED_IMAGE,
  STOP_SEARCH_LOADING,
  STOP_GENERATION_LOADING,
  UPLOAD_IMAGE,
  UPLOAD_LIST_IMAGE,
  RESET_IMAGE_UPLOAD_STATE,
  SET_UPLOAD_IMAGE_LIST,
  FEEDBACK_IMAGE,
  DELETE_IMAGE,
  DELETE_IMAGE_SET, GENERATED_IMAGE_RECEIVED, SET_IMAGE_SEARCH_TEXT, SET_IMAGE_GENERATION_TEXT, SET_ALL_IMAGE_SEARCH_RESULT,
} from './types';
import { isEmpty } from 'lodash';
import Sentry from '../../config/sentryConfig';
import {
  heighlightSelection,
  resetHighlight,
} from 'src/components/TextareaField/helper';
import { outputEditor } from 'src/components/TextareaField/TextareaField';
import { updateGalleryState } from '../myGallery/actions';
import { UploadImageReducer } from 'sharedPackage/src/components/imageSearchComp';
import { t } from 'i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { UploadImageSelector } from './selector';
import { updateSelectedOutputNode } from '../translation/actions';

export const addImageUpload =
  (data: File[], uploadedFrom: 'translation' | 'gallery' = 'translation') =>
  async (dispatch: any, getState: any) => {
    let count = 0;
    // delete the file from the list if it was already uploaded before
    dispatch(updateFileList([...data]));

    // Make the req to upload the file
    const sentRequest = async (file: File) => {
      try {
        const formData = new FormData();
        formData.append('file', file);
        const res = await imageUploadService.uploadImageApi(formData);
        dispatch({
          type: UPLOAD_IMAGE,
          payload: {
            status: 'success',
            id: file.name,
            msg: t('successUpload', { ns: 'easy_language' }),
          },
        });
        if (uploadedFrom === 'gallery') {
          dispatch(updateGalleryState(res.data.data.uuid));
        }
      } catch (e: any) {
        let msg = e?.response?.data?.detail ?? 'errorUpload';
        if (
          msg === 'The user has already mapping to the image in the database'
        ) {
          msg = 'alreadyUploaded';
        } else if (
          msg === 'Images can only have .jpg, .jpeg or .png extension'
        ) {
          msg = 'wrongExtension';
        }
        Sentry.captureMessage(`image upload Error: ${e}`, 'error');
        return dispatch({
          type: UPLOAD_IMAGE,
          payload: {
            msg,
            status: 'error',
            id: file.name,
          },
        });
      } finally {
        if (count < data.length - 1) {
          count += 1;
          sentRequest(data[count]);
        }
      }
    };
    sentRequest(data[count]);
  };

// todo will update this later with the correct api to fetch all the images
// export const getAllUploadedImages = () => async (dispatch: any) => {
//   try {
//     const res = await imageUploadService.getUploadedImageApi();
//     dispatch({
//       type: GET_ALL_UPLOADED_IMAGE,
//       payload: res.data,
//     });
//   } catch (e: any) {
//     return appMessageHandling(
//       dispatch,
//       e.detail || 'error.get_uploaded_image_error',
//       'error'
//     );
//   }
// };

export const feedbackImageUpload =
  (id: string, feedback: 0 | 1 | 2, name: number, isOwner: boolean) =>
  async (dispatch: any) => {
    try {
      const res = await imageUploadService.feedbackImageApi(id, feedback);
      dispatch({
        type: FEEDBACK_IMAGE,
        payload: { ...res.data, name, isOwner },
      });
    } catch (e: any) {
      Sentry.captureMessage(`image upload feedback Error: ${e}`, 'error');
      return appMessageHandling(
        dispatch,
        e.detail || 'error.general_error',
        'error'
      );
    }
  };
export const deleteUploadedImage =
  (id: string, name: number, isOwner: boolean) => async (dispatch: any) => {
    try {
      dispatch(deleteImageSet(id));
      await imageUploadService.deleteUploadedImageApi(id);
      dispatch({
        type: DELETE_IMAGE,
        payload: { id, name, isOwner },
      });
      dispatch(deleteImageSet(null));
    } catch (e: any) {
      Sentry.captureMessage(`image upload delete Error: ${e}`, 'error');
      return appMessageHandling(
        dispatch,
        e.detail || 'error.general_error',
        'error'
      );
    }
  };

export const downloadUploadedImage =
  (id: string) => (dispatch: any, getState: any) => {
    const userEmail = getState().user.userDetails.email;
    const link = document.createElement('a');
    link.href = `${process.env.REACT_APP_DJANGO_BACKEND_DOMAIN}images/download/${id}/${userEmail}`;
    link.setAttribute('download', id);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

export const showMoreImageSearchResults =
  () => async (dispatch: any, getState: any) => {
    const state = getState();
    const loading = UploadImageSelector(state).searchLoading;

    if (loading){
      return;
    }

    let allImageResults = UploadImageSelector(state).allImageResult;
    if (!allImageResults){
      return;
    }

    const currentImageResults = UploadImageSelector(state).imageList;

    let count = Object.keys(currentImageResults?.ownerList ?? {}).length
    count += Object.keys(currentImageResults?.otherList ?? {}).length
    count +=3


    if (count > allImageResults[0].images.length){
      count = allImageResults[0].images.length
    }

    allImageResults = structuredClone(allImageResults)
    allImageResults[0].images = allImageResults[0].images.slice(0, count)

    dispatch({
      type: SEARCH_UPLOADED_IMAGE,
      payload: { list: allImageResults ?? null },
    });

  };

export const searchUploadedImages =
  (
    extract_keywords: boolean = false,
  ) =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    const value = UploadImageSelector(state).imageSearchText;
    let canceled = false;

    if (!isEmpty(value)) {
      try {
        dispatch(searchLoading());
        let res: any = { data: null };
        if (!isEmpty(value)) {
          res = await imageUploadService.searchUploadedImageApi(
            value,
            extract_keywords
          );
        }

        canceled = value != UploadImageSelector(getState()).imageSearchText
        if (canceled){
          return;
        }

        let data = res?.data?.data
        if (data && data.length > 0) {
          let images = data.flatMap((s: any) => s.images) ?? null;
          images.sort((a: any, b: any) => b.similarity - a.similarity);
          data = data.slice(0, 1)
          data[0].images = images
        }

        dispatch({
          type: SET_ALL_IMAGE_SEARCH_RESULT,
          payload: { list: data ?? null },
        });

        if (data && data.length > 0 && data[0].images.length > 0){
          data = structuredClone(data)
          data[0].images = data[0].images.slice(0, 3)
        }

        dispatch({
          type: SEARCH_UPLOADED_IMAGE,
          payload: { list: data ?? null },
        });
      } catch (e: any) {
        console.error(e)
        Sentry.captureMessage(`image upload search Error: ${e}`, 'error');
        dispatch({
          type: SEARCH_UPLOADED_IMAGE,
          payload: { list: null },
        });
        return appMessageHandling(
          dispatch,
          e?.response?.data?.detail ?? 'error.general_error',
          'error'
        );
      } finally {
        if (!canceled){
          dispatch(stopSearchLoading());
        }
      }
    } else if (isEmpty(value)) {
      resetHighlight(outputEditor);
      // return dispatch(emptyStateValueImageUpload({ imageList: null }));
    }
  };

export const generateImages =
  (
    replace = true,
    createPrompt = false,
  ) =>
    async (dispatch: any, getState: any) => {
      const state = getState();
      const value = UploadImageSelector(state).imageGenerationText;
      let canceled = false;

      if (!isEmpty(value)) {
        try {
          dispatch(generationLoading());
          let res: any = { data: null };
          if (!isEmpty(value)) {
            res = await imageUploadService.generateImagesApi(
              value, createPrompt
            );
          }

           canceled = value != UploadImageSelector(getState()).imageGenerationText
          if (canceled){
            return;
          }

          dispatch({
            type: GENERATED_IMAGE_RECEIVED,
            payload: {
              list: res?.data?.images ?? null,
              replace: replace
            },
          });

          if (res?.data?.user_prompt){
            dispatch(setImageGenerationText(res?.data?.user_prompt))
          }
        } catch (e: any) {
          Sentry.captureMessage(`image upload search Error: ${e}`, 'error');
          dispatch({
            type: GENERATED_IMAGE_RECEIVED,
            payload: { list: null },
          });
          return appMessageHandling(
            dispatch,
            e?.response?.data?.detail ?? 'error.general_error',
            'error'
          );
        } finally {
          if (!canceled) {
            dispatch(stopGenerationLoading());
          }
        }
      } else if (isEmpty(value)) {
        resetHighlight(outputEditor);
      }
    };

export const selectImagePlace = (
  value: string,
  nodeKey: string
) =>
  async (dispatch: any, getState: any) => {

    dispatch(setImageSearchText(value))
    dispatch(setImageGenerationText(value))

    dispatch(generateImages(true, true));
    dispatch(searchUploadedImages(true));

    if (nodeKey) {
      dispatch(updateSelectedOutputNode(nodeKey));
    }
  };

export const setImageSearchText = (
  value: string
) =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: SET_IMAGE_SEARCH_TEXT,
      payload: value,
    });
  };

export const setImageGenerationText = (
  value: string
) =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: SET_IMAGE_GENERATION_TEXT,
      payload: value,
    });
  };


export const updateFileList = (data: File[]) => (dispatch: any) => {
  data?.forEach((item: File) => {
    dispatch({
      type: UPLOAD_LIST_IMAGE,
      payload: item.name,
    });
  });
};

export const searchLoading = () => (dispatch: any) => {
  return dispatch({
    type: SEARCH_LOADING,
  });
};

export const generationLoading = () => (dispatch: any) => {
  return dispatch({
    type: GENERATION_LOADING,
  });
};

export const stopSearchLoading = () => (dispatch: any) => {
  return dispatch({
    type: STOP_SEARCH_LOADING,
  });
};

export const stopGenerationLoading = () => (dispatch: any) => {
  return dispatch({
    type: STOP_GENERATION_LOADING,
  });
};

export const emptyStateValueImageUpload =
  (value: UploadImageReducer) => (dispatch: any) => {
    return dispatch({
      type: RESET_IMAGE_UPLOAD_STATE,
      payload: value,
    });
  };

export const setImageUploadList =
  (value: { [key: string]: string }) => (dispatch: any) => {
    return dispatch({
      type: SET_UPLOAD_IMAGE_LIST,
      payload: value,
    });
  };

export const deleteImageSet = (id: string | null) => (dispatch: any) => {
  return dispatch({
    type: DELETE_IMAGE_SET,
    payload: id,
  });
};
