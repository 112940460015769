import { useEffect } from 'react';
import LanguageSwitchBtn from '../languageSwitchComp/LanguageSwitchBtn';
import logo from '../../assets/logo_dark.svg';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { supabaseSelector } from 'src/redux/supabase/selector';
import SignupForm from './SignupForm';
import { Container, Grid } from '@mui/material';
import {
  updateUserAction,
  updateTokenAction,
} from 'src/redux/supabase/actions';

const SignupPage = () => {
  const { t } = useTranslation('common');
  const history = useNavigate();
  const dispatch: any = useDispatch();
  const supabaseUser = useSelector(
    (state: any) => supabaseSelector(state).user,
    shallowEqual
  );
  useEffect(() => {
    if (supabaseUser && !localStorage.getItem('signup_form')) {
      history('/');
    }
    //TODO: fix lint error with more knowledge
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supabaseUser]);

  return (
    <div>
      <Container sx={{ pt: '2em' }}>
        <Grid
          item
          xs={12}
          sx={{
            textAlign: 'right',
          }}
        >
          <LanguageSwitchBtn />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <img
            className="pt-4 mb-5"
            src={logo}
            alt="Logo"
          />

          <SignupForm />

          <div className="w-100 text-center mt-2 mb-5">
            {t('signupForm.alreadyHaveAccount')}{' '}
            <Link
              to="/"
              onClick={() => {
                localStorage.removeItem('SIGNUP_EMAIL');
                localStorage.removeItem('token');

                dispatch(updateUserAction(null));
                dispatch(updateTokenAction(null));
              }}
            >
              {t('toLogin')}
            </Link>
          </div>
        </Grid>
      </Container>
    </div>
  );
};

export default SignupPage;
