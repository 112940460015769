import React, { useState, useRef, useEffect } from 'react';
import TextareaField from '../TextareaField/TextareaField';

import SidebarDrawerComp from '../sidebarDrawerComp/SidebarDrawerComp';
import { useSelector, shallowEqual } from 'react-redux';
import { HistorySelector } from '../../redux/translationHistory/selector';


type Props = {
  outputText: any
  setOutputText(value: any): unknown;
  outputTextValue : any
};

const TranslateTextPage = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [inputText, setInputText] = useState('');
  let inputTextValue = useRef({ text: '' });


  const historyJob = useSelector(
    (state: any) => HistorySelector(state).historyJob,
    shallowEqual
  );

  useEffect(() => {
    if (historyJob && inputTextValue) {
      inputTextValue.current.text = historyJob.originaltext;
    }
  }, [historyJob]);

  // UI
  return (
    <>
      <SidebarDrawerComp
        pageBody={
          <TextareaField
            setOutputText={props.setOutputText}
            inputText={inputText}
            setInputText={setInputText}
            outputText={props.outputText}
            inputTextValue={inputTextValue}
            outputTextValue={props.outputTextValue}
            setOpen={setOpen}
            open={open}
          />
        }
        inputText={inputTextValue.current.text}
        outputText={props.outputTextValue.current.text}
        open={open}
        setOpen={setOpen}
        outputTextValue={undefined}
      />
    </>
  );
};

export default TranslateTextPage;
