import * as React from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const IOSSwitch = styled(
  ({
    onClick,
    checked,
    ...props
  }: {
    onClick: () => void;
    checked: boolean;
  }) => {
    const { t } = useTranslation('A11y');
    return (
      <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
        onClick={onClick}
        aria-label={t('plan_switch')}
      />
    );
  }
)(({ theme }) => ({
  width: 42,
  height: 26,
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  color: theme.palette.primary.main,
  '& .MuiSwitch-switchBase': {
    padding: 1,
    margin: 2,
    transitionDuration: '300ms',
    color: '374d5d',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: theme.palette.primary.main,
      '& + .MuiSwitch-track': {
        backgroundColor: '#ffffff',
        opacity: 1,
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
        color: theme.palette.primary.main,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.primary.main,
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20,
    color: theme.palette.primary.main,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#ffffff',
    opacity: 1,
    border: `1px solid ${theme.palette.primary.main}`,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

interface ToggleSwitchProps {
  leftLabel: string;
  rightLabel: string;
  leftStyle?: React.CSSProperties;
  rightStyle?: React.CSSProperties;
  onClick?: () => void;
  checked: boolean;
}

export default function ToggleSwitch(props: ToggleSwitchProps) {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
    >
      <Typography style={props.leftStyle}>{props.leftLabel}</Typography>

      <IOSSwitch
        sx={{ m: 1 }}
        onClick={props.onClick || (() => {})}
        checked={props.checked}
      />

      <Typography style={props.rightStyle}>{props.rightLabel}</Typography>
    </Stack>
  );
}
