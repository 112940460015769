import { Button } from '@mui/material';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import themeConfig from 'src/theme/themeConfig';

type Props = {
  id: string | number;
  deleteAlert: boolean;
  onDeleteEntry: (row: any, alertTrigger: boolean) => void;
  setDeleteAlert: any;
};

const ConfirmModel = ({
  id,
  deleteAlert,
  onDeleteEntry,
  setDeleteAlert,
}: Props) => {
  const { t } = useTranslation(['common', 'glossary'], {
    nsMode: 'fallback',
  });

  return (
    <Modal
      show={deleteAlert}
      size="lg"
      centered
      onHide={() => {
        setDeleteAlert(false);
      }}
      backdrop="static"
    >
      <Modal.Body>
        <span>{t('delete_entry')}</span>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outlined"
          onClick={(e) => onDeleteEntry(id, true)}
          sx={{
            color: themeConfig.palette.primary.main,
            borderColor: themeConfig.palette.primary.main,
            mr: '10px',
            textTransform: 'capitalize',
            '&:hover': {
              color: themeConfig.palette.primary.main,
              borderColor: themeConfig.palette.primary.main,
            },
          }}
        >
          {t('yesDelete')}
        </Button>
        <Button
          variant="contained"
          onClick={(e) => {
            onDeleteEntry('', false);
          }}
          sx={{
            background: themeConfig.palette.primary.main,
            color: themeConfig.palette.secondary.main,
            textTransform: 'capitalize',
            '&:hover': {
              background: themeConfig.palette.primary.main,
              color: themeConfig.palette.secondary.main,
            },
          }}
        >
          {t('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModel;
