import './GlossaryField.css';
import DeleteButton from './DeleteButton';
import IconButton from '@mui/material/IconButton';
import { Grid, Typography, useTheme } from '@mui/material';
import GlossaryItemView from './GlossaryItemView';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import React, { useMemo } from 'react';
import EditButtonComp from '../../coreUI/editButtonComp/EditButtonComp';
import CopyButtomComp from '../../coreUI/copyButtonComp/CopyButtomComp';
import TextareaComp from '../../coreUI/TextAreaComp/TextareaComp';

type Props = {
  wordEntry: {
    word: string;
    copyRestriction: number;
    id: string;
    explanation: string;
    source: string;
  };
  getFullText: () => void;
  editMode: { [key: string]: any };
  setEditMode: React.Dispatch<React.SetStateAction<{}>>;
  explanationTextEdited: { [key: string]: any };
  setExplanationText: React.Dispatch<React.SetStateAction<{}>>;
  firstItem: boolean;
  lastItem: boolean;
  glossaryForWord: string;
  editExplanationForWord: string;
  updateGlossaryEntry: (value: {
    glossaryID: string;
    explanation: string;
  }) => void;
  deleteConfirm1Word: string;
  deleteConfirm2Word: string;
  noCancelWord?: string;
  yesDeleteWord?: string;
  deleteGlossary: (id: string) => void;
  onlyItem?: boolean;
  copiedText?: string;
  copyText?: string;
  switchValue: boolean;
};

const OwnGlossaryListItem = ({
  wordEntry,
  getFullText,
  editMode,
  setEditMode,
  explanationTextEdited,
  setExplanationText,
  firstItem = false,
  lastItem = false,
  glossaryForWord,
  editExplanationForWord,
  updateGlossaryEntry,
  deleteConfirm1Word,
  deleteConfirm2Word,
  noCancelWord,
  deleteGlossary,
  onlyItem = false,
  yesDeleteWord,
  copiedText,
  copyText,
  switchValue = true,
}: Props) => {
  const theme = useTheme();
  const handleEdite = () => {
    setExplanationText({
      ...explanationTextEdited,
      [wordEntry.id]: wordEntry.explanation,
    });
    setEditMode({ ...editMode, [wordEntry.id]: true });
  };

  const updateGlossary = async () => {
    // send with axios post request to endpoint
    // call api to transform Input original text

    updateGlossaryEntry({
      glossaryID: wordEntry.id,
      explanation: explanationTextEdited[wordEntry.id],
    });
    // getFullText();
  };
  const handleClick = async () => {
    await updateGlossary();
    setEditMode({ ...editMode, [wordEntry.id]: false });
  };

  const copyButtonRender = useMemo(
    () => (
      <Grid
        item
        xs="auto"
      >
        <CopyButtomComp
          value={wordEntry.explanation}
          placement={'top'}
          size={'small'}
          buttonStyle={{
            position: 'relative',
          }}
          copiedText={copiedText}
          copyText={copyText}
        />
      </Grid>
    ),
    [copiedText, copyText, wordEntry.explanation]
  );

  return (
    <GlossaryItemView
      id={`${glossaryForWord} '${wordEntry.word}'`}
      firstItem={firstItem}
      lastItem={lastItem}
      onlyItem={onlyItem}
      accordionSummary={
        // <Typography>
        wordEntry.copyRestriction === 1 ? wordEntry.word + '*' : wordEntry.word
      }
      accordionDetails={
        <Grid
          item
          container
          xs={12}
        >
          <Grid
            item
            xs={12}
          >
            {!editMode[wordEntry.id] && (
              <Typography
                variant="body1"
                sx={{ whiteSpace: 'pre-line' }}
              >
                {wordEntry.explanation}
              </Typography>
            )}
            {editMode[wordEntry.id] && (
              <TextareaComp
                value={explanationTextEdited[wordEntry.id]}
                // disabled={true}
                multiline={true}
                maxRows={7}
                minRows={7}
                style={{
                  // height: heightInput - 37,
                  // minHeight: 337 - 37,
                  width: '100%',
                  height: 'auto',
                  marginBottom: '1rem',
                  '& .base-Input-multiline': {
                    width: '100%',
                    borderRadius: '4px !important',
                    border: `1px solid #ccc`,
                    resize: 'none !important',
                    boxShadow: 'none !important',
                    '&:focus': {
                      outline: 'auto',
                      // boxShadow: 'none !important',
                      borderColor: theme.palette.primary.main,
                    },
                    '&:hover': {
                      // outline: 'none !important',
                      borderColor: theme.palette.primary.main,
                    },
                    '&::placeholder': {
                      color: `${theme.palette.primary.main} !important`,
                    },
                  },
                }}
                handleChange={(event: any) => {
                  setExplanationText({
                    ...explanationTextEdited,
                    [wordEntry.id]: event.target.value,
                  });
                }}
                name="explanationEditTextArea"
                placeholder={editExplanationForWord}
              />
            )}
          </Grid>
          <Grid
            item
            container
            xs={12}
            sx={{
              justifyContent: 'right',
            }}
          >
            {/* {editMode[wordEntry.id] && copyButtonRender} */}
            {!editMode[wordEntry.id] ? (
              <>
                <Grid
                  item
                  xs="auto"
                >
                  <EditButtonComp handleClick={handleEdite} />
                </Grid>
                {copyButtonRender}
              </>
            ) : (
              <Grid
                item
                container
                xs="auto"
              >
                <Grid
                  item
                  xs="auto"
                >
                  {' '}
                  <IconButton
                    onClick={(e) => {
                      setEditMode({ ...editMode, [wordEntry.id]: false });
                    }}
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                  >
                    <CloseOutlinedIcon
                      sx={{ color: '#374d5d' }}
                      fontSize="small"
                    />
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs="auto"
                >
                  <IconButton
                    onClick={handleClick}
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                  >
                    <CheckOutlinedIcon
                      sx={{ color: '#374d5d' }}
                      fontSize="small"
                    />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      }
      expandIcon={
        wordEntry.source !== 'na' && (
          <DeleteButton
            wordToDelete={wordEntry.word}
            glossaryId={wordEntry.id}
            deleteConfirm1Word={deleteConfirm1Word}
            deleteConfirm2Word={deleteConfirm2Word}
            yesDeleteWord={deleteConfirm2Word}
            noCancelWord={noCancelWord}
            confirmButtonText={yesDeleteWord}
            deleteGlossary={deleteGlossary}
            switchValue={switchValue}
          />
        )
      }
    />
  );
};

export default OwnGlossaryListItem;
