import TranslationStepSwitch from './TranslationStepSwitch';
import { useRef, useState } from 'react';
import TranslateTextPage from './TranslateTextPage';
import AddImagePage from './AddImagePage';

const TranslationFlowPage = () => {
  const [addImage, switchState] = useState(false);
  const [outputText, setOutputText] = useState('');
  let outputTextValue = useRef({ text: '' });
  return (
    <div>
      <TranslationStepSwitch switchStep={switchState} addImage={addImage} />
      {addImage ?
        <AddImagePage
          outputText={outputText}
          setOutputText={setOutputText}
          outputTextValue={outputTextValue}
        /> :
        <TranslateTextPage
          outputText={outputText}
          setOutputText={setOutputText}
          outputTextValue={outputTextValue}
        />}
    </div>
  );
}


export default TranslationFlowPage;