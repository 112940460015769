import { Dispatch, Ref, SetStateAction } from 'react';
import InputTextFileComp from './InputTextFileComp';
import InputTypeChangeButton from './InputTypeChangeButton';
import TranslationInputHeader from './TranslationInputHeader';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../../../theme/themeConfig';

type Props = {
  fileInput: any;
  switchInput: (value: boolean) => void;
  setInputText: Dispatch<SetStateAction<string>>;
  editorValue: (text: string, refEditor: Ref<any>) => void;
  disableInputField: boolean;
  inputText: string;
  inputTextValue: { current: { text: string } };
  // isempty: boolean;
  inputRefEditor: any;
};

const TranslationInputWraper = ({
  editorValue,
  inputRefEditor,
  ...props
}: Props) => {
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Grid
      item
      container
      xs={12}
      sx={{ height: props.fileInput ? '100%' : 'auto' }}
    >
      <Grid
        item
        xs={12}
        sx={{
          padding: matchesSM ? 'auto' : '0 3%',
        }}
      >
        <TranslationInputHeader />
      </Grid>
      <InputTextFileComp
        switchInput={props.switchInput}
        fileInput={props.fileInput}
        setInputText={props.setInputText}
        editorValue={editorValue}
        inputRefEditor={inputRefEditor}
        disableInputField={props.disableInputField}
        inputText={props.inputText}
        inputTextValue={props.inputTextValue}
      />
      <InputTypeChangeButton
        switchInput={props.switchInput}
        fileInput={props.fileInput}
      />
    </Grid>
  );
};

export default TranslationInputWraper;
