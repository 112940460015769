import { Col, Form, Row } from 'react-bootstrap';
import style from './SideMenu.module.css';
import { useTheme } from '@mui/material';
type Props = {
  label: string;
  value: string;
};
const ContainerField = (props: Props) => {
  const theme = useTheme();
  return (
    <Row>
      <Col className="col-4 text-left mb-2">
        <Form.Label column={true}>{props.label}</Form.Label>
      </Col>
      <Col>
        <Form.Control
          placeholder={props.value}
          disabled
          className={style['form-control']}
          style={{ color: theme.palette.primary.main }}
        />
      </Col>
    </Row>
  );
};

export default ContainerField;
