import SignUpField from './SignUpField';
import CompanyField from './CompanyField';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { Alert, Grid, Link } from '@mui/material';

type Props = {};
const SignupForm = (props: Props) => {
  const { t } = useTranslation(['common', 'account'], {
    nsMode: 'fallback',
  });
  return (
    <Grid
      item
      container
      xs={5.5}
      margin={'auto'}
      spacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <h3 className="pt-2 text-center mb-4">
          {localStorage.getItem('signup_form')
            ? t('signupForm.registrationDetails')
            : t('signupForm.registration')}
        </h3>
        <Alert
          severity="info"
          icon={false}
          sx={{ justifyContent: 'center' }}
        >
          <Typography
            variant="subtitle1"
            gutterBottom
            style={{
              whiteSpace: 'pre-line',
            }}
          >
            {t('infoContactSales')}
            <Link
              href="https://summ-ai.com/gespraech-vereinbaren/"
              target="_blank"
              rel="noopener"
            >
              {t('contact_us')}
            </Link>
          </Typography>
        </Alert>
      </Grid>
      <Grid
        item
        xs={12}
      >
        {localStorage.getItem('signup_form') ? (
          <CompanyField />
        ) : (
          <SignUpField />
        )}
      </Grid>
    </Grid>
  );
};

export default SignupForm;
