import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { TranslateSelector } from 'src/redux/translation/selector';

type Props = {
  translateLanguage: () => void;
  loadingTranslation: boolean;
};

const TranslateButtonComp = ({
  loadingTranslation,
  translateLanguage,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  const emptyInput = useSelector(
    (state: any) => TranslateSelector(state).emptyInput,
    shallowEqual
  );

  return (
    <LoadingButton
      // loadingIndicator={t('translationbutton.loading')}
      variant="contained"
      // className="translationButton"
      onClick={translateLanguage}
      disabled={loadingTranslation || emptyInput}
      loading={loadingTranslation}
      sx={{
        // width: 'auto',
        marginTop: '1rem',
        fontSize: '1rem',
        background: `${theme.palette.general.goldenYellow} !important`,
        color: theme.palette.primary.main,
        textTransform: 'capitalize',
        '&:hover': {
          background: theme.palette.general.goldenYellow,
          color: theme.palette.primary.main,
        },
        '& .MuiLoadingButton-loadingIndicator': {
          // background: theme.palette.general.goldenYellow,
          color: theme.palette.primary.main,
        },
        // '&:disabled': {
        //   color: theme.palette.secondary.main,
        // },
      }}
    >
      <span>{t('translationbutton.default')}</span>
    </LoadingButton>
  );
};

export default TranslateButtonComp;
