import { InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type Props = {
  setEntries: any;
  entries: string;
};

const RowToDisplay = ({ setEntries, entries }: Props) => {
  const { t } = useTranslation(['glossary', 'common'], {
    nsMode: 'fallback',
  });
  return (
    <Col
      md={{ span: 'auto' }}
      style={{
        position: 'absolute',
        right: '1px',
        top: '20px',
        marginRight: '10px',
      }}
    >
      <FormControl
        sx={{
          width: '10em',
          height: '2em',
          fontSize: '0.8rem',
          borderRadius: '2px',
        }}
      >
        <InputLabel
          htmlFor="Glossary-entries-select-label"
          className="bg-white"
          size="small"
        >
          {t('glossaryEntries.noOfEntries')}
        </InputLabel>
        <Select
          labelId="Glossary-entries-select-label"
          value={entries}
          onChange={(event) => setEntries(event?.target.value)}
          size="small"
        >
          {[10, 20, 60, 100].map((value) => (
            <MenuItem
              key={value}
              value={value}
            >
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Col>
  );
};

export default RowToDisplay;
