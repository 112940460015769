import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// import { useAuth } from '../../contexts/AuthContext';
import LoadingBanner from '../../coreUI/loadingBanner/LoadingBanner';
import { shallowEqual, useSelector } from 'react-redux';
import { UserSelector } from '../../redux/user/selector';

export default function BetaRoute() {
  const userDetails = useSelector(
    (state: any) => UserSelector(state).userDetails,
    shallowEqual
  );

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userDetails?.betauser !== null) {
      setLoading(false);
    }
  }, [userDetails?.betauser]);

  return loading ? (
    <LoadingBanner />
  ) : userDetails?.betauser ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
}
