import { Config } from './selector';
import {
  TRIGGER_ALERT,
  REMOVE_ALERT,
  SIDE_BAR_TAB,
  RESIZE_TEXTAREA_BOX,
  UPDATE_HISTORY_SETTING,
} from './types';

const initialState: Config = {
  trigger: false,
  AlertMessage: { msg: '', variant: 'default', options: {} },
  sidebarTab: 0,
  textareaDimension: { width: 756, height: 373 },
  historyTransSetting: {
    filters: { field: 'originaltext', operation: 'contains', value: '' },
    langLevel: '',
    entriesNum: '10',
  },
};

export default function alertReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case TRIGGER_ALERT:
      return {
        ...state,
        trigger: true,
        AlertMessage: {
          msg: payload.msg,
          variant: payload.variant,
          options: payload?.options ?? {},
        },
      };
    case REMOVE_ALERT:
      return {
        ...state,
        trigger: false,
        AlertMessage: { msg: '', variant: '', options: {} },
      };
    case SIDE_BAR_TAB:
      return {
        ...state,
        sidebarTab: payload,
      };
    case RESIZE_TEXTAREA_BOX:
      return {
        ...state,
        textareaDimension: { width: payload.width, height: payload.height },
      };
    case UPDATE_HISTORY_SETTING:
      return {
        ...state,
        historyTransSetting: {
          ...state.historyTransSetting,
          ...payload,
        },
      };
    default:
      return state;
  }
}
