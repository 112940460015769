import {
  DELETE_IMAGE_GALLERY,
  FETCH_MY_GALLERY,
  LOADING,
  STOP_LOADING,
  UPDATE_GALLERY_STATE,
} from './types';

const initialState: any = {
  galleryList: null,
  count: 0,
  nextPage: null,
  previousPage: null,
  currentPage: 1,
  entries: '10',
  loading: false,
};

export default function MyGalleryReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_MY_GALLERY:
      let itemUpdate = {};
      payload.results?.forEach((item: { uuid: string }) => {
        itemUpdate = {
          ...itemUpdate,
          [item.uuid]: item,
        };
      });
      return {
        ...state,
        galleryList: { images: itemUpdate, is_owner: true },
        count: payload.count,
        nextPage: payload.next,
        previousPage: payload.previous,
        currentPage: payload.page,
        entries: payload.page_size,
      };
    case DELETE_IMAGE_GALLERY:
      let imageList = { ...state.galleryList.images };
      delete imageList[payload];
      return {
        ...state,
        galleryList: {
          images: imageList,
          is_owner: true,
        },
        count: state.count - 1,
        currentPage: state.count - 1 <= 10 ? 1 : state.currentPage,
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_GALLERY_STATE:
      let oldList = state.galleryList;

      return {
        ...state,
        galleryList: {
          images: {
            ...(oldList.images
              ? { ...oldList.images, [payload]: { uuid: payload } }
              : { [payload]: { uuid: payload } }),
          },
          is_owner: true,
        },
        count: state.galleryList.images[payload]
          ? state.count
          : state.count + 1,
        currentPage: state.galleryList.images[payload]
          ? state.currentPage
          : state.count + 1 >= 10
          ? 1
          : state.currentPage,
      };
    default:
      return state;
  }
}
