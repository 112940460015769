import React, { Ref, RefObject } from 'react';
import TextEditorComp from 'src/coreUI/textEditorComp/TextEditorComp';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { UserSelector } from 'src/redux/user/selector';
import { TranslateSelector } from 'src/redux/translation/selector';
import { isEmpty, debounce } from 'lodash';
import { autoDetectLang } from 'src/redux/translation/actions';
import { LexicalEditor } from 'lexical';
import DragAndDrop from './DragAndDrop';
import { useMediaQuery } from '@mui/material';
import { updateUserInfo } from 'src/redux/user/actions';

type Props = {
  switchInput: (value: boolean) => void;
  fileInput: boolean;
  setInputText: React.Dispatch<React.SetStateAction<string>>;
  editorValue: (text: string, refEditor: Ref<any>) => void;
  inputRefEditor: RefObject<LexicalEditor>;
  disableInputField: boolean;
  inputText: string;
  inputTextValue: { current: { text: string } };
};

const InputTextFileComp = ({
  switchInput,
  fileInput,
  setInputText,
  editorValue,
  inputRefEditor,
  disableInputField,
  inputText,
  inputTextValue,
}: Props) => {
  const { t } = useTranslation(['easy_language', 'common', 'A11y'], {
    nsMode: 'fallback',
  });
  const dispatch: any = useDispatch();
  const hasGroup = useSelector(
    (state: any) => UserSelector(state).hasGroup,
    shallowEqual
  );
  const isLargeScreen = useMediaQuery('(max-width:1513px)');

  const transjob = useSelector(
    (state: any) => TranslateSelector(state).transjob,
    shallowEqual
  );

  const userDetails = useSelector(
    (state: any) => UserSelector(state).userDetails,
    shallowEqual
  );

  const transConfig = useSelector(
    (state: any) => TranslateSelector(state).transConfig,
    shallowEqual
  );

  const debounceDetectLang = debounce((text) => {
    if (
      !isEmpty(text) &&
      transConfig?.preferred_translation_language?.[
        `preferred_trans_language_${userDetails?.preferred_output_language_type}`
      ] === 'multi'
    ) {
      dispatch(autoDetectLang(text));
    }
  }, 500);

  return (
    <Grid
      item
      container
      xs={12}
      sx={{ position: 'relative' }}
    >
      <Grid
        item
        xs={12}
        sx={{
          height: '95.5%',
          width: !isLargeScreen ? '96.3%' : '100%',
          zIndex: fileInput ? 2 : 1,
          position: 'absolute',
          visibility: fileInput ? 'visible ' : 'hidden',
          margin: !isLargeScreen ? '0px 0px 20px 2%' : '0',
        }}
      >
        <DragAndDrop
          setInputText={setInputText}
          switchInput={switchInput}
          editorValue={editorValue}
          inputRefEditor={inputRefEditor}
        />
      </Grid>
      <Tooltip
        title={hasGroup ? undefined : t('output.hasNoGroup')}
        placement="top"
      >
        <Grid
          item
          xs={12}
          sx={{
            height: '100%',
            zIndex: fileInput ? 1 : 2,
            visibility: fileInput ? 'hidden' : 'visible',
          }}
        >
          <TextEditorComp
            disableInputField={disableInputField}
            inputText={inputText}
            textValue={inputTextValue}
            refEditor={inputRefEditor}
            placeholder={t('originaltext.textarea.placeholder')}
            showCounter={true}
            counterTitle={t('charactercount')}
            id={'translation-input'}
            showToolbar={true}
            showSwitch={true}
            editable={hasGroup}
            complexityScore={
              false && userDetails?.previewFeatures && transjob?.text_metrics
                ? `${Math.round(transjob.text_metrics.input_lix_score)} (${t(
                    transjob.text_metrics.input_lix_score_display
                  )})`
                : ''
            }
            complexityScoreTitle={t('complexityScoreTitle')}
            comlexityScoreDescription={t('complexityInfo')}
            displayImage={false}
            ariaLabel={t('textfield_orginal')}
            featureInclude={[
              'fontFamily',
              'fontSize',
              'redo',
              'undo',
              'bold',
              'italic',
              'underline',
              'leftAlign',
              'justifyAlign',
              'centerAlign',
              'insertLink',
              'copy',
              'blockOption',
            ]}
            debounceDetectLang={debounceDetectLang}
            switchValue={userDetails?.format_input_switch}
            handleSwitchFormatting={(value) =>
              dispatch(updateUserInfo({ format_input_switch: value }))
            }
          />
        </Grid>
      </Tooltip>
    </Grid>
  );
};

export default InputTextFileComp;
