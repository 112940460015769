import {
  Container,
  Grid,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material';
import Fade from '@mui/material/Fade';
import NewLine from './NewLine';
import SeparatorComp from './SeparatorComp';
import TransLanguageComp from './TransLanguageComp';
import { dataType, outputLanguageType } from './ouputTextSetting.types';
import React, { createContext, useMemo } from 'react';

type Props = {
  showFirstTimeUpdateSnackbar: () => void;
  settingTitle: string;
  settingDescrip: string;
  separatorType: dataType;
  separatorLabel: string;
  toolTipsTitle: string;
  outputLanguage: outputLanguageType;
  handleSetSeparatorStyle: (e: SelectChangeEvent<any>) => void;
  separateVal: string;
  transLangMenu: dataType;
  transLangLabel: string;
  handelSetTransLang: (e: SelectChangeEvent<string>) => void;
  transLangVal: string;
  newLineLanguage: dataType;
  newLineLabel: string;
  newLineVal: string;
  handelSetNewLine: (e: SelectChangeEvent) => void;
  containerClassName?: string;
  titleStyle?: object;
  descrStyle?: object;
  selectStyle?: object;
};

export const SettingCompContext = createContext<{ selectStyle?: object }>({});
const SettingComp = ({
  showFirstTimeUpdateSnackbar,
  settingTitle,
  settingDescrip,
  separateVal,
  separatorLabel,
  toolTipsTitle,
  separatorType,
  outputLanguage,
  handleSetSeparatorStyle,
  transLangVal,
  transLangLabel,
  handelSetTransLang,
  transLangMenu,
  newLineLanguage,
  newLineLabel,
  newLineVal,
  handelSetNewLine,
  containerClassName,
  titleStyle = {},
  descrStyle = {},
  selectStyle = {},
}: Props) => {
  const theme = useTheme();

  const value = useMemo(() => {
    return {
      selectStyle,
    };
  }, [selectStyle]);
  return (
    <SettingCompContext.Provider value={value}>
      <Fade in={true}>
        <Container className={containerClassName}>
          <Grid
            item
            xs={12}
          >
            <Typography
              variant="h2"
              gutterBottom
              sx={{
                color: `${theme.palette.general.goldenYellow} !important`,
                fontSize: '1.5rem !important',
                fontWeight: 'bold !important',
                marginBottom: '0 !important',
                ...titleStyle,
              }}
            >
              {settingTitle}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                color: `${theme.palette.general.goldenYellow} !important`,
                ...descrStyle,
              }}
            >
              {settingDescrip}
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            spacing={2}
          >
            <Grid
              item
              md={5}
              xs={12}
            >
              <SeparatorComp
                showFirstTimeUpdateSnackbar={showFirstTimeUpdateSnackbar}
                separatorType={separatorType}
                separatorLabel={separatorLabel}
                toolTipsTitle={toolTipsTitle}
                outputLanguage={outputLanguage}
                handleSetSeparatorStyle={handleSetSeparatorStyle}
                separateVal={separateVal}
              />
            </Grid>
            <Grid
              item
              md={5}
              xs={12}
            >
              <TransLanguageComp
                transLangMenu={transLangMenu}
                transLangLabel={transLangLabel}
                handelSetTransLang={handelSetTransLang}
                transLangVal={transLangVal}
              />
            </Grid>
            <Grid
              item
              md={5}
              xs={12}
            >
              <NewLine
                newLineLanguage={newLineLanguage}
                newLineLabel={newLineLabel}
                newLineVal={newLineVal}
                handelSetNewLine={handelSetNewLine}
              />
            </Grid>
          </Grid>
        </Container>
      </Fade>
    </SettingCompContext.Provider>
  );
};

export default SettingComp;
