import React, { FC } from 'react';
import LogoutButton from '../../utils/LogoutButton';
import logo from '../../../assets/logo_dark.svg';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

const ServerDownWarning: FC = () => {
  const { t } = useTranslation('dashboard');
  const theme = useTheme();
  return (
    <>
      <img
        className="mt-5 mb-2"
        src={logo}
        alt="Logo"
      />

      <h5
        className="mt-4 mb-3"
        style={{ color: theme.palette.primary.main }}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: t('warnings.serverDown', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </h5>
      <LogoutButton />
      <div className="mb-5"></div>
    </>
  );
};

export default ServerDownWarning;
