import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LogoutButton from '../utils/LogoutButton';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { supabaseSelector } from 'src/redux/supabase/selector';
import { resendEmailVerificationAction } from 'src/redux/supabase/actions';
import { supabase } from 'src/config/supabase';

const OptinWaitingSupabase = () => {
  const { t } = useTranslation('dashboard');
  const dispatch = useDispatch();
  const history = useNavigate();
  const supabaseUser = useSelector(
    (state) => supabaseSelector(state).user,
    shallowEqual
  );
  const supabaseSession = useSelector(
    (state) => supabaseSelector(state).session,
    shallowEqual
  );

  useEffect(() => {
    if (supabaseUser && supabaseSession) {
      history('/');
    }
    //TODO: fix lint error with more knowledge
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshPage = () => {
    window.location.reload(false);
    // trackEvent({ category: 'button', action: 'optin - refresh page' });
  };

  const handleResendVerificationEmail = () => {
    dispatch(resendEmailVerificationAction());
    // trackEvent({
    //   category: 'button',
    //   action: 'optin - resend verification email',
    // });
  };

  // call refreshToken every 5 seconds
  useEffect(() => {
    supabase.auth.startAutoRefresh();
    return () => supabase.auth.stopAutoRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Row>
        <Col className="text-left">
          <p className="pt-2 mb-4 mt-5 bold">
            <span
              dangerouslySetInnerHTML={{
                __html: t('waitingAlert.email.part1', {
                  interpolation: { escapeValue: false },
                }),
              }}
            />{' '}
            {localStorage.getItem('email')}{' '}
            <span
              dangerouslySetInnerHTML={{
                __html: t('waitingAlert.email.part2', {
                  interpolation: { escapeValue: false },
                }),
              }}
            />
          </p>

          <p>
            <span
              dangerouslySetInnerHTML={{
                __html: t('waitingAlert.alreadyConfirmed.part1', {
                  interpolation: { escapeValue: false },
                }),
              }}
            />{' '}
            <button
              onClick={refreshPage}
              className="link-button font-weight-bold"
            >
              {t('waitingAlert.here')}
            </button>
            {t('waitingAlert.alreadyConfirmed.part2')}
          </p>
          <p>
            <span
              dangerouslySetInnerHTML={{
                __html: t('waitingAlert.checkSpam.part1', {
                  interpolation: { escapeValue: false },
                }),
              }}
            />
            <button
              onClick={handleResendVerificationEmail}
              className="link-button font-weight-bold"
            >
              {t('waitingAlert.here')}
            </button>

            <span
              dangerouslySetInnerHTML={{
                __html: t('waitingAlert.checkSpam.part2', {
                  interpolation: { escapeValue: false },
                }),
              }}
            />
          </p>
        </Col>
      </Row>
      <LogoutButton />
    </Container>
  );
};

export default OptinWaitingSupabase;
