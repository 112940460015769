import {
  GridColDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
} from '@mui/x-data-grid';
import { Button } from 'react-bootstrap';
import { GlossaryItem } from 'src/types/glossaryTypes.types';
import GlossaryEditTextarea from './GlossaryEditTextarea';
import GlossaryTags from './GlossaryTags';
import GlossaryTextArea from './GlossaryTextArea';
import { FilterValue } from 'src/types/transHistory.types';
import DeleteIcon from '@mui/icons-material/Delete';
import { FixMe } from 'src/types/types';
import {
  dataComponentConfig,
  inputComponentConfig,
} from 'src/utils/tableFilterHelper';
import moment from 'moment';
import themeConfig from 'src/theme/themeConfig';
import styles from './GlossaryEditTextarea.module.css';

const renderDate = (params: any) => {
  const date = moment(params.value).format('DD.MM.YYYY');
  const time = moment(params.value).format('h:mm:ss a');

  return (
    <div style={{ whiteSpace: 'pre-line', textAlign: 'left' }}>
      <p>
        {date}
        <br />
        {time}
      </p>
    </div>
  );
};

export const columns: (
  activeId: string,
  setActiveId: any,
  filter: any,
  t: any,
  handleConfirmFIlter: (data: FilterValue) => void,
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>, row: FixMe) => void,
  removeTag: (index: number, row: FixMe) => void,
  onDeleteEntryTrigger: (id: number) => void
) => readonly GridColDef<any>[] = (
  activeId,
  setActiveId,
  filter,
  t,
  handleConfirmFIlter,
  handleKeyDown,
  removeTag,
  onDeleteEntryTrigger
) => [
  {
    field: 'timestamp_created',
    headerName: t('glossaryEntries.headers.date'),
    flex: 0.3,
    align: 'left',
    minWidth: 200,
    renderCell: renderDate,
    sortable: false,
    filterOperators: [
      {
        label: 'is after',
        value: 'after',
        ...dataComponentConfig(handleConfirmFIlter, filter, 'after'),
      },
      {
        label: 'is before',
        value: 'before',
        ...dataComponentConfig(handleConfirmFIlter, filter, 'before'),
      },
      {
        label: 'equal',
        value: 'is',
        ...dataComponentConfig(handleConfirmFIlter, filter, 'is'),
      },
    ],
  },
  {
    field: 'word',
    headerName: t('glossaryEntries.headers.word'),
    flex: 1,
    editable: true,
    minWidth: 200,
    renderCell: (params: GridRenderCellParams<String>) => (
      <GlossaryTextArea
        params={params}
        setActiveId={setActiveId}
        activeId={activeId}
        headerName={t('glossaryEntries.headers.word')}
      />
    ),
    renderEditCell: (params: GridRenderEditCellParams) => (
      <GlossaryEditTextarea
        id={params.id}
        value={params.value}
        field={params.field}
        activeId={activeId!}
        row={params.row}
        headerName={t('glossaryEntries.headers.word')}
      />
    ),
    sortable: false,
    filterOperators: [
      inputComponentConfig(handleConfirmFIlter, filter, 'word'),
    ],
  },
  {
    field: 'explanation',
    headerName: t('glossaryEntries.headers.explanation'),
    flex: 1,
    editable: true,
    minWidth: 200,
    renderCell: (params: GridRenderCellParams) => (
      <GlossaryTextArea
        params={params}
        setActiveId={setActiveId}
        activeId={activeId!}
        headerName={t('glossaryEntries.headers.explanation')}
      />
    ),
    renderEditCell: (params: GridRenderEditCellParams) => (
      <GlossaryEditTextarea
        id={params.id}
        value={params.value}
        field={params.field}
        activeId={activeId!}
        row={params.row}
        headerName={t('glossaryEntries.headers.explanation')}
      />
    ),
    sortable: false,
    filterOperators: [
      inputComponentConfig(handleConfirmFIlter, filter, 'explanation'),
    ],
    cellClassName: styles['glossary-explanation-cell'],
  },
  {
    field: 'tags',
    headerName: t('glossaryEntries.headers.tags'),
    // headerClassName: 'translation-table-header',
    flex: 0.7,
    minWidth: 200,
    renderCell: (params: GridRenderCellParams) => (
      <GlossaryTags
        params={params}
        removeTag={removeTag}
        handleKeyDown={handleKeyDown}
      />
    ),
    sortable: false,
    filterOperators: [
      inputComponentConfig(handleConfirmFIlter, filter, 'tags'),
    ],
  },
  {
    field: 'delete_button',
    disableExport: true,
    headerName: t('glossaryEntries.headers.delete'),
    flex: 0.2,
    renderCell: (params: GridRenderCellParams<GlossaryItem>) => (
      <div>
        <Button
          variant=""
          className="glossaryEntryDelete"
          name="eintrag löschen"
          onClick={(e) => {
            onDeleteEntryTrigger(params.row.id);
          }}
          aria-label={t('glossaryEntries.deleteEntry') + ': ' + params.row.word}
        >
          <DeleteIcon sx={{ color: themeConfig.palette.primary.main }} />
        </Button>
      </div>
    ),
    sortable: false,
    filterable: false,
  },
];
