import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  generateImages,
  feedbackImageUpload, setImageGenerationText,
} from '../../redux/imageUpload/actions';
import { UploadImageSelector } from '../../redux/imageUpload/selector';
import { TranslateSelector } from '../../redux/translation/selector';
import { insertImageToTable } from '../../redux/translation/actions';
import ImageGenerationComp from '@summ-ai-github/summ-frontend-package/components/imageGenerationComp/ImageGenerationComp';

type Props = {
  isExpanded: boolean;
};

const ImageGenerationWrapper = (props: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  const dispatch: any = useDispatch();
  const tempImageList = useSelector(
    (state: any) => UploadImageSelector(state).tempImageList,
    shallowEqual
  );

  const generatedImages = useSelector(
    (state: any) => UploadImageSelector(state).generatedImages,
    shallowEqual
  );

  const loading = useSelector(
    (state: any) => UploadImageSelector(state).generationLoading,
    shallowEqual
  );

  const uploadedImageList = useSelector(
    (state: any) => UploadImageSelector(state).uploadedImageList
  );

  const deletedImgId = useSelector(
    (state: any) => UploadImageSelector(state).deletedImgId
  );
  const selectedOutputNode = useSelector(
    (state: any) => TranslateSelector(state).selectedOutputNode,
    shallowEqual
  );

  const imageGenerationText = useSelector(
    (state: any) => UploadImageSelector(state).imageGenerationText
  );

  return (
    <ImageGenerationComp
      searchUploadedImages={(value: any) => dispatch(generateImages(value))}
      generatedImages={generatedImages}
      tempImageList={tempImageList}
      loading={loading}
      langWords={{
        ListUploadTitle: t('ListUploadTitle'),
        searchWord: t('image.generateImages'),
        searchImage: t('searchImage'),
        myGallary: t('myGallary'),
        bilder: t('bilder'),
        licenseImage: t('licenseImage'),
        uploading: t('uploading'),
        dragUploadImage: t('dragUploadImage'),
        dragAndDropSplitterText: t('input.dragAndDropSplitterText'),
        buttonUpload: t('buttonUpload'),
        noImage: t('noImage'),
      }}
      handleInsertImage={(url: string) => dispatch(insertImageToTable(url))}
      feedbackImageUpload={(
        id: string,
        feedback: 0 | 1 | 2,
        imageId: number,
        isOwner: boolean
      ) => dispatch(feedbackImageUpload(id, feedback, imageId, isOwner))}
      selectedOutputNode={selectedOutputNode}
      uploadedImageList={uploadedImageList}
      deletedImgId={deletedImgId}
      isExpanded={props.isExpanded}
      imageGenerationText={imageGenerationText}
      setImageGenerationText={(value:string) => dispatch(setImageGenerationText(value))}
    />
  );
};

export default ImageGenerationWrapper;
