export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const LOADING = 'LOADING_USER';
export const STOP_LOADING = 'STOP_LOADING_USER';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const UPDATE_USER_DETAILS_API = 'UPDATE_USER_DETAILS_API';
export const GET_USER_API_KEY = 'GET_USER_API_KEY';
export const CREATE_USER_API_KEY = 'CREATE_USER_API_KEY';
export const DELETE_USER_API_KEY = 'DELETE_USER_API_KEY';
export const UPDATE_USER_API_KEY = 'UPDATE_USER_API_KEY';
export const REST_CREATED_API_KEY = 'REST_CREATED_API_KEY';
export const UPDATE_QUOTA_LIMIT = 'UPDATE_QUOTA_LIMIT';
export const UPDATE_QUOTA_SPENT = 'UPDATE_QUOTA_SPENT';
export const NEW_USER_SIGNUP = 'NEW_USER_SIGNUP';
export const EMPTY_STATE_VALUE = 'EMPTY_STATE_VALUE_USER';
export const USER_EXIST_CHECK = 'USER_EXIST_CHECK';
export const USER_REVIEW = 'USER_REVIEW';
export const UPDATE_SEPARATOR_STYLE = 'UPDATE_SEPARATOR_STYLE';
