import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  emptyStateValueImageUpload,
  searchUploadedImages,
  downloadUploadedImage,
  feedbackImageUpload,
  deleteUploadedImage, setImageSearchText,
} from '../../redux/imageUpload/actions';
import { UploadImageSelector } from '../../redux/imageUpload/selector';
import { TranslateSelector } from '../../redux/translation/selector';
import { insertImageToTable } from '../../redux/translation/actions';
import ImageSearchComp, {
  UploadImageReducer,
} from '@summ-ai-github/summ-frontend-package/components/imageSearchComp';

type Props = {
  isExpanded: boolean;
};

const ImageSearchCompWrapper = (props: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  const dispatch: any = useDispatch();

  const imageList = useSelector(
    (state: any) => UploadImageSelector(state).imageList,
    shallowEqual
  );

  const loading = useSelector(
    (state: any) => UploadImageSelector(state).searchLoading,
    shallowEqual
  );

  const uploadedImageList = useSelector(
    (state: any) => UploadImageSelector(state).uploadedImageList
  );

  const deletedImgId = useSelector(
    (state: any) => UploadImageSelector(state).deletedImgId
  );
  const selectedOutputNode = useSelector(
    (state: any) => TranslateSelector(state).selectedOutputNode,
    shallowEqual
  );

  const imageSearchText = useSelector(
    (state: any) => UploadImageSelector(state).imageSearchText
  );

  return (
    <ImageSearchComp
      emptyStateValueImageUpload={(data: UploadImageReducer) =>
        dispatch(emptyStateValueImageUpload(data)) as void
      }
      searchUploadedImages={(value: any) =>
        dispatch(searchUploadedImages())
      }
      imageList={imageList}
      loading={loading}
      langWords={{
        ListUploadTitle: t('ListUploadTitle'),
        searchWord: t('image.searchImages'),
        searchImage: t('searchImage'),
        myGallary: t('myGallary'),
        bilder: t('bilder'),
        licenseImage: t('licenseImage'),
        uploading: t('uploading'),
        dragUploadImage: t('dragUploadImage'),
        dragAndDropSplitterText: t('input.dragAndDropSplitterText'),
        buttonUpload: t('buttonUpload'),
        noImage: t('noImage'),
      }}
      downloadUploadedImage={(id: string) =>
        dispatch(downloadUploadedImage(id))
      }
      handleInsertImage={(url: string) => dispatch(insertImageToTable(url))}
      feedbackImageUpload={(
        id: string,
        feedback: 0 | 1 | 2,
        imageId: number,
        isOwner: boolean
      ) => dispatch(feedbackImageUpload(id, feedback, imageId, isOwner))}
      deleteUploadedImage={(id: string, imageId: number, isOwner: boolean) =>
        dispatch(deleteUploadedImage(id, imageId, isOwner))
      }
      selectedOutputNode={selectedOutputNode}
      deletedImgId={deletedImgId}
      isExpanded={props.isExpanded}
      imageSearchText={imageSearchText}
      setImageSearchText={(value) => dispatch(setImageSearchText(value))}
    />
  );
};

export default ImageSearchCompWrapper;
