import ContainerField from './ContainerField';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { UserSelector } from '../../redux/user/selector';
import { Grid } from '@mui/material';

const AccountContainer = () => {
  const userDetails: any = useSelector(
    (state: any) => UserSelector(state).userDetails,
    shallowEqual
  );
  let zipCityField = '';
  if (userDetails?.company?.zipcode && userDetails?.company?.city) {
    zipCityField =
      userDetails?.company?.zipcode + ' ' + userDetails?.company?.city;
  }

  let nameField = '';
  if (userDetails?.forename && userDetails?.surname) {
    nameField = userDetails?.forename + ' ' + userDetails?.surname;
  }

  const { t } = useTranslation('account');

  return (
    <>
      <Grid
        item
        xs={12}
        textAlign={'left'}
        fontSize={'1.5rem'}
      >
        <h4>{t('account.header')}</h4>
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent={'right'}
      >
        <Grid
          item
          xs={10}
        >
          {' '}
          <ContainerField
            label={t('account.email') + ':'}
            value={userDetails?.email}
          />
        </Grid>
        <Grid
          item
          xs={10}
        >
          {' '}
          <ContainerField
            label={t('account.customernumber') + ':'}
            value={userDetails?.customernumber}
          />
        </Grid>
        <Grid
          item
          xs={10}
        >
          <ContainerField
            label={t('account.name') + ':'}
            value={nameField}
          />
        </Grid>
        <Grid
          item
          xs={10}
        >
          {' '}
          <ContainerField
            label={t('account.street') + ':'}
            value={userDetails?.company?.street}
          />
        </Grid>
        <Grid
          item
          xs={10}
        >
          <ContainerField
            label={t('account.zipCity') + ':'}
            value={zipCityField}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AccountContainer;
