import React, { Ref } from 'react';
import { useRef } from 'react';
import { useDropzone, FileRejection } from 'react-dropzone';
import Grid from '@mui/material/Grid';
import DragDropComp from 'src/coreUI/dragAndDropComp/DragDropComp';
import { useDispatch } from 'react-redux';
import { fileTextExtraction } from 'src/redux/translation/actions';
import { editorValueClear } from '../helper';
import { appMessageHandling } from 'src/utils/errorHandler';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';

type Props = {
  switchInput: (value: boolean) => void;
  setInputText: React.Dispatch<React.SetStateAction<string>>;
  editorValue: (text: string, refEditor: Ref<any>, id: string) => void;
  inputRefEditor: Ref<any>;
};

const DragAndDrop = ({
  editorValue,
  inputRefEditor,
  setInputText,
  switchInput,
}: Props) => {
  const dispatch: any = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  const [loadingState, setLoadingState] = React.useState<boolean>(false);
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'));

  const matchesMD = useMediaQuery(theme.breakpoints.down('lg'));
  const matchesXL = useMediaQuery(theme.breakpoints.down('xl'));
  const { getRootProps, getInputProps, isDragAccept, isDragReject, open } =
    useDropzone({
      accept: {
        'text/word': ['.docx', '.docs', '.doc'],
        'application/pdf': ['.pdf'],
        'text/plain': ['.txt'],
        'text/rtf': ['.rtf'],
      },
      noClick: true,
      noKeyboard: true,
      maxFiles: 1,
      maxSize: 15 * 1024 * 1024, // file isze must not exceed 10mb,
      onDropAccepted: (acceptedFiles) => {
        acceptedFiles.forEach(async (file: File) => {
          setLoadingState(true);
          const fileText = await dispatch(fileTextExtraction(file));
          setLoadingState(false);
          if (fileText?.text) {
            editorValueClear(inputRefEditor);
            editorValue(fileText.text, inputRefEditor, 'translation-input');
            // pass text to translation field
            setInputText(fileText.text);
            // enable translation field
            switchInput(false);
          }
        });
      },
      onDropRejected: (files, e) => {
        files.map((file: FileRejection) =>
          appMessageHandling(
            dispatch,
            file.errors[0].code === 'file-too-large'
              ? 'File is too large'
              : file.errors[0].message,
            'error'
          )
        );
      },
    });
  const { ref } = getRootProps();
  const dragRef: any = useRef(null);

  return (
    <Grid
      item
      container
      xs={12}
      ref={ref}
      sx={{
        padding: matchesSM ? '0 !important' : 'auto',

        maxWidth: '100% !important',
        paddingLeft: matchesMD ? 'auto' : '12px !important',
        height: '-webkit-fill-available',
        ...(matchesXL && { paddingLeft: '21px', paddingRight: '21px' }),
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <DragDropComp
        isDragAccept={isDragAccept}
        isDragReject={isDragReject}
        open={open}
        dragRef={dragRef}
        langWords={{
          dragUploadType: t('input.dragAndDropText'),
          dragAndDropSplitterText: t('input.dragAndDropSplitterText'),
          buttonUpload: t('input.dragAndDropButtonText'),
        }}
        loading={loadingState}
        containerStyle={{
          borderColor: isDragAccept
            ? theme.palette.general.goldenYellow
            : isDragReject
            ? theme.palette.error.main
            : theme.palette.general.white,
        }}
        fileButton={{
          '& .MuiLoadingButton-loadingIndicator': {
            color: theme.palette.general.goldenYellow,
          },
        }}
      />
    </Grid>
  );
};

export default DragAndDrop;
