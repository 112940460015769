import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Grow,
  IconButton,
  useTheme,
  Box,
} from '@mui/material';
import React, { useState } from 'react';
import { menuListItem, actionButton } from './fixedData';
import MenuListComp from '../menuComp/MenuListComp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ImageUploadItem } from 'src/types/imageUpload.types';
import { useTranslation } from 'react-i18next';
import LoadingOverlay from '@summ-ai-github/summ-frontend-package/coreUI/loadingOverlay/LoadingOverlay';

type Props = {
  imageItem:
    | ImageUploadItem
    | null
    | { images: { uuid: string }[]; is_owner: boolean };
  handleDelete?: (id: string) => void;
  handleDownload?: (id: string) => void;
  handleFeedback?: (id: string, feedback: 0 | 1 | 2) => void;
  handleInsertImage?: (url: string) => void;
  cols?: number;
  rowHeight?: number;
  imageListStyle?: object;
  disableInsertImage?: boolean;
  curentPage?: number;
  deletedImgId: string | null;
};

const ImageListItemView = ({
  imageItem,
  handleDelete,
  handleDownload,
  handleFeedback,
  handleInsertImage,
  cols = 2,
  rowHeight = 200,
  imageListStyle = {},
  disableInsertImage = false,
  curentPage = 1,
  deletedImgId,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation(['gallery'], {
    nsMode: 'fallback',
  });
  const [viewActions, setViewActions] = useState<string | number>('');

  return (
    <ImageList
      sx={{ width: '100%', height: '100%', ...imageListStyle }}
      cols={cols}
      rowHeight={rowHeight}
    >
      {(imageItem && Object.values(imageItem?.images))
        ?.sort((a: any, b: any) => b.similarity - a.similarity)
        .splice(0, 10) // slice the image depending on the current page
        .map(
          (item: {
            uuid: string;
            similarity?: number;
            feedback?: 0 | 1 | 2;
          }) => (
            <Box
              sx={{
                position: 'relative',
              }}
              key={item.uuid}
            >
              <LoadingOverlay
                loading={deletedImgId === item.uuid}
                size="50px"
                children={
                  <ImageListItem
                    style={{
                      height: 'fit-content',
                      filter:
                        deletedImgId === item.uuid
                          ? 'opacity(50%)'
                          : 'opacity(100%)',
                    }}
                    id={item.uuid}
                    onMouseOver={() => setViewActions(item.uuid)}
                    onMouseLeave={() => setViewActions('')}
                  >
                    <img
                      srcSet={`${process.env.REACT_APP_DJANGO_BACKEND_DOMAIN}images/proxy/${item.uuid}`}
                      src={`${process.env.REACT_APP_DJANGO_BACKEND_DOMAIN}images/proxy/${item.uuid}`}
                      alt={item.uuid}
                      loading="lazy"
                      style={{
                        objectFit: 'contain',
                        width: '100%',
                        height: 'auto',
                        aspectRatio: '26 / 18',
                      }}
                    />
                    {imageItem?.is_owner && (
                      <ImageListItemBar
                        sx={{
                          background: 'transparent',
                          padding: '5px',
                          paddingRight: '0 !important',
                        }}
                        actionPosition="right"
                        position="top"
                        actionIcon={
                          <MenuListComp
                            list={menuListItem(t, item, handleDelete).list}
                            menuView={
                              <MoreHorizIcon sx={{ fontSize: '16px' }} />
                            }
                            title={t('img_opt')}
                            type="iconButton"
                            menuStyle={{
                              '.MuiMenu-list': {
                                paddingBottom: 0,
                                paddingTop: 0,
                              },
                            }}
                            buttonstyle={{
                              p: '3px',
                              ml: '5px',
                              borderRadius: '11%',
                              background: `${theme.palette.secondary.main}`,
                              color: theme.palette.primary.main,
                              '&:hover': {
                                color: theme.palette.primary.main,
                                background: `${theme.palette.secondary.main}`,
                              },
                            }}
                          />
                        }
                      />
                    )}
                    <Grow in={item.uuid === viewActions}>
                      <ImageListItemBar
                        actionPosition="right"
                        position="bottom"
                        title={undefined}
                        sx={{
                          background: 'transparent',
                          padding: '5px',
                          paddingRight: '0 !important',
                          justifyContent: 'right',
                          '& .MuiImageListItemBar-titleWrap': {
                            display: 'none',
                          },
                        }}
                        actionIcon={actionButton(
                          handleDownload,
                          item,
                          handleFeedback,
                          handleInsertImage
                        ).map(({ Icon, action, label }) => (
                          <IconButton
                            key={label}
                            sx={{
                              color: theme.palette.primary.main,
                              p: '3px',
                              ml: '5px',
                              borderRadius: '11%',
                              background: `${theme.palette.secondary.main}`,
                              '&:hover': {
                                color: theme.palette.primary.main,
                                background: `${theme.palette.secondary.main}`,
                              },
                            }}
                            aria-label={`${label} ${item.uuid}`}
                            onClick={() => {
                              action();
                            }}
                            disabled={
                              label === 'insert image'
                                ? disableInsertImage
                                : false
                            }
                          >
                            <Icon sx={{ fontSize: '16px' }} />
                          </IconButton>
                        ))}
                      />
                    </Grow>
                  </ImageListItem>
                }
              />
            </Box>
          )
        ) ?? <>rr</>}
    </ImageList>
  );
};

export default ImageListItemView;
