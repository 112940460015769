import { useTheme } from '@mui/material';
import React from 'react';

type Props = {
  title: string;
};

const PageTitle = ({ title }: Props) => {
  const theme = useTheme();
  return (
    <h1
      className="mt-4 mb-4"
      style={{
        textTransform: 'capitalize',
        fontSize: '2rem',
        width: 'fit-content',
        margin: 'auto',
        position: 'relative',
        fontWeight: 600,
      }}
    >
      {title}
      <span
        style={{
          width: '100%',
          height: '7px',
          display: 'block',
          background: theme.palette.general.goldenYellow,
          position: 'absolute',
          bottom: '5px',
          zIndex: '-1',
        }}
      />
    </h1>
  );
};

export default PageTitle;
