import {
  Autocomplete,
  Chip,
  IconButton,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  tagsUpdate,
  updateTransHistory,
} from 'src/redux/translationHistory/actions';
import { HistorySelector } from 'src/redux/translationHistory/selector';
import { useState } from 'react';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

type Props = {
  paramsVal: any;
};

const Tags = ({ paramsVal }: Props) => {
  const dispatch: any = useDispatch();
  const theme = useTheme();
  const [inputVal, setInputVal] = useState<string>('');
  // const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });

  const tags = useSelector(
    (state: any) => HistorySelector(state).tags,
    shallowEqual
  );

  const updateTags = async (row: any, tags: string[]) => {
    dispatch(
      updateTransHistory({
        id: row.id,
        simpleTextUserEdited: row.simpletextuseredited,
        userFeedback: row.userfeedback,
        category: row.category,
        tags: tags.join(','),
      })
    );
  };

  function handleKeyDown(row: { id: string }) {
    if (!inputVal?.trim() || tags[paramsVal.row.id].includes(inputVal?.trim()))
      return;

    dispatch(tagsUpdate({ ...tags, [row.id]: [...tags[row.id], inputVal] }));
    setInputVal('');

    updateTags(row, [...tags[row.id], inputVal]);
  }

  function removeTag(row: any, value: string) {
    var temp = tags[row.id];
    temp = temp.filter((item: string, i: number) => item !== value);
    dispatch(tagsUpdate({ ...tags, [row.id]: temp }));
    updateTags(row, temp);
  }

  return (
    <Autocomplete
      multiple
      fullWidth
      limitTags={1}
      clearIcon={<></>}
      id="trans-history-tags"
      size="small"
      noOptionsText={t('no_options')}
      options={tags[paramsVal.row.id] || []}
      getOptionLabel={(option) => option}
      defaultValue={tags[paramsVal.row.id] || []}
      isOptionEqualToValue={(option, value) => true}
      value={tags[paramsVal.row.id] || []}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleKeyDown(paramsVal.row);
        }
      }}
      onKeyDownCapture={(e) => {
        if (e.key !== 'Enter') {
          e.stopPropagation();
        }
      }}
      renderTags={(value, getTagProps) => (
        <>
          {tags[paramsVal.row.id]?.slice(0, 1)?.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              size="small"
              {...getTagProps({ index })}
              onDelete={(e) => removeTag(paramsVal.row, option)}
              key={`${index}-${option}`}
              sx={{
                display: 'inline-flex',
                width: '6em',
                background: theme.palette.general.lightBlue,
                borderColor: theme.palette.general.lightSteelBlue,
                borderRadius: '5px',
                justifyContent: 'space-between',
                '& .MuiChip-deleteIcon': {
                  color: theme.palette.primary.main,
                  '&:hover': {
                    color: theme.palette.secondary.main,
                  },
                },
              }}
            />
          ))}
          {tags[paramsVal.row.id]?.length > 1 && (
            <Typography variant="caption">{`+${
              tags[paramsVal.row.id]?.length - 1
            }`}</Typography>
          )}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('history.enterTags')}
          placeholder={t('history.enterTags')}
          onChange={(e) => setInputVal(e?.target.value)}
          value={inputVal}
        />
      )}
      renderOption={(
        props: object,
        option,
        state: object,
        ownerState: object
      ) => (
        <ListItem
          {...props}
          key={option}
          dense={true}
          secondaryAction={
            <IconButton
              edge="end"
              aria-label={t('delete_tag')}
              onClick={() => removeTag(paramsVal.row, option)}
              sx={{
                color: theme.palette.general.goldenYellow,
              }}
            >
              <DeleteIcon sx={{ color: theme.palette.primary.main }} />
            </IconButton>
          }
          sx={{
            wordBreak: 'break-word',
            background: `${theme.palette.general.white} !important`,
          }}
        >
          <ListItemText primary={option} />
        </ListItem>
      )}
      sx={{
        '& .MuiAutocomplete-inputRoot': {
          pr: '23px !important',
        },
      }}
    />
  );
};

export default Tags;
