import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

type Props = {
  tableHeader: { id: string; content: string }[];
  tableContent: any[] | null;
  showActions?: boolean;
  handleDelete?: (item: object) => void;
  handleEdit?: (item: object) => void;
};

const BasicTableComp = ({
  tableHeader,
  tableContent,
  showActions = false,
  handleDelete,
  handleEdit,
}: Props) => {
  const { t } = useTranslation('A11y');
  const theme = useTheme();
  return (
    <TableContainer>
      <Table
        // sx={{ minWidth: 650 }}
        aria-label={t('table') as string}
      >
        <TableHead>
          <TableRow>
            {tableHeader.map((item) => (
              <TableCell
                key={item.id}
                align="left"
              >
                {item.content}
              </TableCell>
            ))}
            <TableCell align="left">{''}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableContent &&
            tableContent.map((row, index) => (
              <TableRow key={`${index}-row`}>
                {tableHeader.map((item, key) => (
                  <TableCell
                    component="th"
                    scope="row"
                    key={`${key}-cell`}
                  >
                    {row[item.id]}
                  </TableCell>
                ))}
                {showActions && (
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    <IconButton
                      aria-label={t('edit')}
                      size="small"
                      onClick={() => handleEdit && handleEdit(row)}
                    >
                      <EditIcon sx={{ color: theme.palette.primary.main }} />
                    </IconButton>
                    <IconButton
                      aria-label={t('delete')}
                      size="small"
                      onClick={() => handleDelete && handleDelete(row)}
                    >
                      <DeleteIcon sx={{ color: theme.palette.primary.main }} />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTableComp;
