import { GridColDef, useGridApiContext } from '@mui/x-data-grid';
import TextDisplay from './TextDisplay';
import EditTextAreaInputCell from './EditTextAreaInputCell';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { FilterValue } from 'src/types/transHistory.types';
import {
  dataComponentConfig,
  inputComponentConfig,
  selectComponentConfig,
} from 'src/utils/tableFilterHelper';
import Grid from '@mui/material/Grid';
import { Button, IconButton } from '@mui/material';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import FeatureComp from 'src/coreUI/featureComp/FeatureComp';
import { FeatureSelector } from 'src/redux/features/selector';
import { HistorySelector } from 'src/redux/translationHistory/selector';
import { useMemo } from 'react';
import { updateFeature } from 'src/redux/features/actions';
import ActionWrapper from './ActionWrapper';
import DeleteIcon from '@mui/icons-material/Delete';
import { textTypeFilterList } from './fixedData';
import { useTranslation } from 'react-i18next';
import themeConfig from 'src/theme/themeConfig';
import styles from './TranslationHistory.module.css';
import { updateUserInfo } from 'src/redux/user/actions';
import { UserSelector } from 'src/redux/user/selector';
const Textarea = (params: any, setActiveId: any, activeId: string) => {
  const { id, value, field } = params;
  const apiRef = useGridApiContext();

  const handleClick = () => {
    apiRef.current.startCellEditMode({ id: id, field: field });
    setActiveId(id);
  };

  let cellValue = params.row.text_user_edited || value;

  return (
    <TextDisplay
      params={params}
      activeId={activeId}
      value={cellValue}
      handleClick={handleClick}
    />
  );
};

const RenderDate = (params: any, handleSetNewJob: (row: any) => void) => {
  const date = params.value.toLocaleDateString('de-DE');
  const time = params.value.toLocaleTimeString('de-DE');
  const { t } = useTranslation(['easy_language', 'A11y']);
  const dispatch: any = useDispatch();
  const featuresList = useSelector(
    (state: any) => FeatureSelector(state).featuresList,
    shallowEqual
  );
  const jobsList = useSelector(
    (state: any) => HistorySelector(state).jobsList,
    shallowEqual
  );
  const userDetails: any = useSelector(
    (state: any) => UserSelector(state).userDetails,
    shallowEqual
  );

  let index = useMemo(() => Object.values(jobsList)?.reverse(), [jobsList]);
  return (
    <Grid
      item
      container
      xs={12}
      spacing={2}
    >
      <Grid
        item
        container
        xs={12}
      >
        <Grid
          item
          xs={12}
        >
          {date}
        </Grid>
        <Grid
          item
          xs={12}
        >
          {time}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <FeatureComp
          show={
            featuresList && index[0]?.id === params?.row?.id
              ? featuresList[5]?.seen
              : true
          }
        >
          <Button
            variant="outlined"
            size="small"
            sx={{
              textTransform: 'none',
              fontSize: '.8rem',
            }}
            onClick={async () => {
              if (featuresList && !featuresList[5]?.seen) {
                dispatch(updateFeature(5));
              }

              const containsHtmlTags = params.row.input_text_type === 'Html';

              // turn on format input switch if html tags are present
              if (containsHtmlTags && !userDetails.format_input_switch) {
                await dispatch(updateUserInfo({ format_input_switch: true }));
              } else if (!containsHtmlTags && userDetails.format_input_switch) {
                await dispatch(updateUserInfo({ format_input_switch: false }));
              }

              handleSetNewJob(params.row);
            }}
            endIcon={<OpenInNewIcon />}
          >
            {t('openNewTrans')}
          </Button>
        </FeatureComp>
      </Grid>
    </Grid>
  );
};

function EditInputCell(params: any, setActiveId: any, activeId: string) {
  params = { ...params, setActiveId, activeId };
  let cellValue = params.row.text_user_edited || params.value;
  return (
    <EditTextAreaInputCell
      {...params}
      value={cellValue}
    />
  );
}

export const columns: (
  activeId: string,
  setActiveId: any,
  filter: any,
  handleConfirmFIlter: (data: FilterValue) => void,
  t: any,
  handleSetNewJob: (row: any) => void,
  onDeleteEntry: (row: any, alertTrigger: boolean) => void,
  selectedRow: number,
  dispatch: any
) => readonly GridColDef<any>[] = (
  activeId,
  setActiveId,
  filter,
  handleConfirmFIlter,
  t,
  handleSetNewJob,
  onDeleteEntry,
  selectedRow
) => [
  {
    field: 'timestampcreated',
    type: 'date',
    headerName: t('history.headers.date'),
    headerClassName: 'translation-table-header',
    flex: 0.2,
    align: 'left',
    minWidth: 150,
    renderCell: (params) => RenderDate(params, handleSetNewJob),
    sortable: false,
    filterable: false,
    hideable: false,
    filterOperators: [
      {
        label: t('operators.is_after', { ns: 'common' }),
        value: 'after',
        ...dataComponentConfig(handleConfirmFIlter, filter, 'after'),
      },
      {
        label: t('operators.is_before', { ns: 'common' }),
        value: 'before',
        ...dataComponentConfig(handleConfirmFIlter, filter, 'before'),
      },
      {
        label: t('operators.equal', { ns: 'common' }),
        value: 'is',
        ...dataComponentConfig(handleConfirmFIlter, filter, 'is'),
      },
    ],
  },
  {
    field: 'originaltext',
    headerName: t('history.headers.ogText'),
    headerClassName: 'translation-table-header',
    flex: 1,
    minWidth: 200,
    hideable: false,
    renderCell: (params: any) => {
      return (
        <TextDisplay
          params={params}
          activeId={activeId}
          value={params.value}
          id="original-text"
        />
      );
    },
    sortable: false,
    filterOperators: [
      inputComponentConfig(handleConfirmFIlter, filter, 'originaltext'),
    ],
  },
  {
    field: 'simpletext',
    headerName: t('history.headers.easyLang'),
    headerClassName: 'translation-table-header',
    flex: 1,
    minWidth: 200,
    renderCell: (params: any) => {
      return (
        <TextDisplay
          params={params}
          activeId={activeId}
          value={params.value}
        />
      );
    },
    sortable: false,
    filterOperators: [
      inputComponentConfig(handleConfirmFIlter, filter, 'simpletext'),
    ],
  },
  {
    field: 'simpletextuseredited',
    headerName: t('history.headers.suggestedImprovement'),
    headerClassName: 'translation-table-header',
    flex: 1,
    minWidth: 200,
    editable: true,
    hideable: false,

    renderCell: (params: any) => Textarea(params, setActiveId, activeId),
    renderEditCell: (params: any) =>
      EditInputCell(params, setActiveId, activeId),
    sortable: false,
    filterOperators: [
      inputComponentConfig(handleConfirmFIlter, filter, 'simpletextuseredited'),
    ],
  },
  {
    field: 'category',
    headerName: t('history.headers.properties'),
    headerClassName: styles['history-actions-header'],
    flex: 1,
    minWidth: 300,
    renderCell: (params: any) => (
      <ActionWrapper
        paramsVal={params}
        onDeleteEntry={onDeleteEntry}
        selectedRow={selectedRow}
      />
    ),
    sortable: false,
    filterOperators: [
      selectComponentConfig(
        filter,
        textTypeFilterList(t),
        handleConfirmFIlter,
        'category'
      ),
    ],
  },
  {
    field: 'delete_action',
    headerName: t('history.headers.delete'),
    headerClassName: 'translation-table-header',
    flex: 0.5,
    headerAlign: 'center',
    renderCell: (params) => (
      <IconButton
        onClick={() => onDeleteEntry(params, true)}
        aria-label={t('clear_translation')}
        // sx={{ height: 'fit-content', verticalAlign: 'middle' }}
      >
        <DeleteIcon
          fontSize="large"
          sx={{ color: themeConfig.palette.primary.main }}
        />
      </IconButton>
    ),
    sortable: false,
    filterable: false,
  },
];
