import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import ProductCard from './ProductCard';
import OrderForm from './orderForm/OrderForm';
import Cookies from 'universal-cookie';
import { ChevronLeft } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import ToggleSwitch from '../../widgets/ToggleSwitch';
import InfoComponent from '../../widgets/InfoComponent';
import HelpButton from './HelpButton';
import styles from './CheckoutPage.module.css';

const CheckoutPage = () => {
  const cookies = new Cookies();
  const history = useNavigate();
  if (!cookies.get('selectedProduct')) {
    history('/summ-products');
  }
  const [product, setProduct] = useState(
    cookies.get('selectedProduct') ? cookies.get('selectedProduct') : {}
  );
  const [checked, setChecked] = useState(
    cookies.get('selectedProduct')
      ? product.licencePeriod === 'Jährlich'
      : false
  );

  const toggleProduct = () => {
    let changingProduct = product;
    if (product.licencePeriod === 'Monatlich') {
      changingProduct = { ...changingProduct, licencePeriod: 'Jährlich' };
      setProduct(changingProduct);
      setChecked(true);
    } else {
      changingProduct = { ...changingProduct, licencePeriod: 'Monatlich' };
      setProduct(changingProduct);
      setChecked(false);
    }
  };

  return (
    cookies.get('selectedProduct') && (
      <Container className={styles.baseContainer}>
        <Row className="mt-4">
          <Col md={2}></Col>

          <Col
            md={{ span: 8, offset: 2 }}
            className="text-left"
          ></Col>
        </Row>
        <Row className="mt-5">
          <Col className={styles.productDiv}>
            <Button
              variant=""
              onClick={() => {
                history('/summ-products');
              }}
            >
              <ChevronLeft />
              <span> Zurück zur Produktauswahl</span>
            </Button>
            <div className={styles.toggleDiv}>
              <ToggleSwitch
                leftLabel={'monatlich'}
                rightLabel={'jährlich'}
                rightStyle={{
                  fontWeight: 'bold',
                }}
                checked={checked}
                onClick={toggleProduct}
              />
            </div>
            <br />
            <div className={styles.productCard}>
              <ProductCard
                product={product}
                licencePeriod={product.licencePeriod}
                handleProductSelection={(selectedProduct: any) =>
                  setProduct(selectedProduct)
                }
              />
            </div>
          </Col>

          <Col md={5}>
            <h1 className={styles.accountHeading}>
              Bestellvorgang abschließen
            </h1>

            <OrderForm selectedProduct={product} />
          </Col>
          <Col>
            <InfoComponent
              heading="Informationen zur kostenfreien Testphase"
              body={
                <>
                  <span>
                    Für alle Pakete gilt: <b>14 Tage kostenfrei</b> testen -
                    damit Sie sich selbst von SUMM überzeugen können!
                  </span>
                  <span>
                    Wie funktioniert die Testphase? Innerhalb von 14 Tagen nach
                    Freischaltung Ihres Accounts haben Sie die Möglichkeit, alle
                    Funktionen des gewählten Pakets kostenfrei und
                    uneingeschränkt zu verwenden. Wenn Sie ihre Bestellung
                    innerhalb dieses Zeitraums per E-Mail an{' '}
                    <a href="mailto:kuendigung@summ-ai.com">
                      kuendigung@summ-ai.com
                    </a>{' '}
                    unter Angabe Ihrer Kundennummer sowie Ihrer verwendeter
                    E-Mail-Adresse kündigen, endet Ihre Lizenz nach 14 Tagen und
                    Sie zahlen selbstverständlich nichts.
                  </span>
                </>
              }
            />
            <div className={styles.helpButton + ' text-right'}>
              <HelpButton />
            </div>
          </Col>
        </Row>
      </Container>
    )
  );
};

export default CheckoutPage;
