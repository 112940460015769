import { Dispatch, SetStateAction, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FeedbackText } from '../../fixedData';
import ShowFeedbackModal from './ShowFeedbackModal';
import { useTranslation } from 'react-i18next';
import { Button, useTheme } from '@mui/material';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ThumbDownAltRoundedIcon from '@mui/icons-material/ThumbDownAltRounded';
type Props = {
  // setShowInfoBorderOutput(value: boolean): void;
  setShowTooltipOutput(value: boolean): void;
  outputRefEditor: any;
  setShowFeedbackButtons(value: boolean): void;
  setShowSubmitAdjustedButton: Dispatch<SetStateAction<boolean>>;
  updateSimpleTextUserEdited: (
    value: FeedbackText,
    userFeedbackText?: string
  ) => void;
};

const FeedbackButtonsComp = (props: Props) => {
  const theme = useTheme();
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });

  const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false); // state that controls visibility of negative feedback modal
  const [negFeedback, setNegFeedback] = useState<string>('wrongContent'); // state that stores negative feedback text
  // const negFeedback: any = useRef();
  const handleAdjustment = () => {
    // show tooltip over output field and highlight box
    // props.setShowInfoBorderOutput(true);
    props.setShowTooltipOutput(true);
    props.outputRefEditor.current.focus();

    setTimeout(() => {
      props.setShowTooltipOutput(false);
    }, 10 * 1000);

    // hide all Feedback buttons
    props.setShowFeedbackButtons(false);

    // show new button for submit
    props.setShowSubmitAdjustedButton(true);
  };

  return (
    <>
      <Form.Group className="feedbackContainer">
        <Button
          className="w-100"
          onClick={() => props.updateSimpleTextUserEdited('positive')}
          sx={{
            background: theme.palette.general.goldenYellow,
            color: theme.palette.primary.main,
            textTransform: 'none',
            fontSize: '1rem',
            '&:hover': {
              background: theme.palette.general.goldenYellow,
              color: theme.palette.primary.main,
            },
          }}
        >
          {t('feedback.positive.buttonText')}{' '}
          <ThumbUpAltRoundedIcon
            style={{
              marginLeft: '5px',
              fontSize: '1.2rem',
              fill: 'none',
              stroke: theme.palette.primary.main,
            }}
          />
        </Button>
        <Button
          className="mt-2 w-100"
          onClick={handleAdjustment}
          sx={{
            background: theme.palette.general.goldenYellow,
            color: theme.palette.primary.main,
            textTransform: 'none',
            fontSize: '1rem',
            '&:hover': {
              background: theme.palette.general.goldenYellow,
              color: theme.palette.primary.main,
            },
          }}
        >
          {t('feedback.edit.buttonText')}{' '}
          <EditRoundedIcon
            style={{
              marginLeft: '5px',
              fontSize: '1.2rem',
              fill: 'none',
              stroke: theme.palette.primary.main,
            }}
          />
        </Button>
        <Button
          className="mt-2 w-100"
          onClick={() => setShowFeedbackModal(true)}
          sx={{
            background: theme.palette.general.goldenYellow,
            color: theme.palette.primary.main,
            textTransform: 'none',
            fontSize: '1rem',
            '&:hover': {
              background: theme.palette.general.goldenYellow,
              color: theme.palette.primary.main,
            },
          }}
        >
          {t('feedback.negative.buttonText')}{' '}
          <ThumbDownAltRoundedIcon
            style={{
              marginLeft: '5px',
              fontSize: '1.2rem',
              fill: 'none',
              stroke: theme.palette.primary.main,
            }}
          />
        </Button>
      </Form.Group>
      <ShowFeedbackModal
        showFeedbackModal={showFeedbackModal}
        setShowFeedbackModal={setShowFeedbackModal}
        negFeedback={negFeedback}
        setNegFeedback={setNegFeedback}
        updateSimpleTextUserEdited={props.updateSimpleTextUserEdited}
      />
    </>
  );
};

export default FeedbackButtonsComp;
