import { TFunction } from 'i18next';
import { SeparatorValue } from 'src/types/translation.types';

export const languageType: (
  t: TFunction
) => { id: string; value: SeparatorValue; text: string }[] = (t: TFunction) => [
  {
    id: `1-hyphen`,
    value: 'hyphen',
    text: `${t('toolbox.separator.hyphen')} (-)`,
  },
  {
    id: '2-interpunct',
    value: 'interpunct',
    text: `${t('toolbox.separator.medio')} (·)`,
  },
  {
    id: '3-none',
    value: 'none',
    text: t('toolbox.separator.none'),
  },
];

export const newLanguage = (t: TFunction) => [
  {
    id: `1-newline`,
    value: 'True',
    text: `${t('toolbox.newLine.newLinePerSentence')}`,
  },
  {
    id: '2-no-newline',
    value: 'False',
    text: `${t('toolbox.newLine.continousText')}`,
  },
];

export const transLangVal = (t: any, autoLangDetect?: string | null) => [
  {
    id: '1-transLang',
    value: 'multi',
    text: `${t('auto-lang', {
      lang: autoLangDetect ? `(${autoLangDetect})` : '',
    })}`,
    label: t('auto-lang'),
  },
  {
    id: `2-transLang`,
    value: 'de',
    text: `Deutsch`,
    label: t('germanLang'),
  },
  {
    id: `3-transLang`,
    value: 'en',
    text: `English`,
    label: t('englishLang'),
  },
  {
    id: `4-transLang`,
    value: 'fr',
    text: `Français`,
    label: t('frenchLang'),
  },
];

export const langOption: (
  userPermissionsEasy: boolean,
  userPermissionsPlain: boolean,
  t: any
) => { id: number; value: string; text: string }[] | [] = (
  userPermissionsEasy,
  userPermissionsPlain,
  t
) => {
  let value: { id: number; value: string; text: string }[] = [];
  if (userPermissionsEasy) {
    value = [
      ...value,
      {
        id: 1,
        value: 'easy',
        text: t('output.easyLang'),
      },
    ];
  }

  if (userPermissionsPlain) {
    value = [
      ...value,
      {
        id: 2,
        value: 'plain',
        text: t('output.plainLang'),
      },
    ];
  }
  return value;
};

export const negativeFeedbackOpt = (t: any) => [
  {
    id: 'nf-1',
    value: 'wrongContent',
    text: t('feedback.negative.wrongContent'),
  },
  {
    id: 'nf-2',
    value: 'additionalInformation',
    text: t('feedback.negative.informationAddition'),
  },
  {
    id: 'nf-3',
    value: 'missingInformation',
    text: t('feedback.negative.importantInfoSkipped'),
  },
  {
    id: 'nf-4',
    value: 'complicatedText',
    text: t('feedback.negative.complicatedText'),
  },
  {
    id: 'nf-5',
    value: 'other',
    text: t('feedback.negative.otherReason'),
  },
];

export type FeedbackText = 'positive' | 'adjusted' | 'negative';
export const feedbackTexts: (t: any) => { [key in FeedbackText]: string } = (
  t: any
) => {
  return {
    positive: `${t('feedback.thanks')} 🙂 \n${t('feedback.positive.response', {
      interpolation: { escapeValue: false },
    })}`,
    adjusted: `${t('feedback.thanks')} 🙂 \n${t('feedback.edit.response')}`,
    negative: `${t('feedback.thanks')} 🙂 \n${t('feedback.negative.response', {
      interpolation: { escapeValue: false },
    })}`,
  };
};
