import axios from 'axios';
import { isEmpty } from 'lodash';
import { clearSessionandLocalStorge } from 'src/utils/clearStorage';
export const controller = new AbortController();
// This can be used in future when user logout and we want to cancel
// all the api request he was making
export const signal = { signal: controller.signal };
const http = axios.create({
  baseURL: process.env.REACT_APP_DJANGO_BACKEND_DOMAIN,
  // withCredentials:true,
  headers: {
    'Content-type': 'application/json',
  },
});

http.interceptors.request.use((req: any) => {
  const localStorageToken = localStorage.getItem('token') || '';

  let token = isEmpty(localStorageToken) ? {} : JSON.parse(localStorageToken);
  if (token && token.access_token) {
    req.headers['Authorization'] = `Bearer ${token.access_token}`;
  }
  return req;
});

http.interceptors.response.use(
  (res: any) => {
    return res;
  },
  (e: any) => {
    if (
      e?.response?.status === 401 &&
      ['Token is not valid', 'User does not exist'].includes(
        e?.response?.data.detail
      )
    ) {
      clearSessionandLocalStorge();
      window.location.href = '/';
    }
    throw e;
  }
);

export default http;
