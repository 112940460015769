export const categoryoptions: (
  t: any
) => { id: string; value: string; text: string }[] = (t) => [
  {
    id: 'cat-1',
    value: 'translator',
    text: t('signupForm.translator'),
  },
  {
    id: 'cat-2',
    value: 'officeManager',
    text: t('signupForm.officeManagement'),
  },
  {
    id: 'cat-3',
    value: 'other',
    text: t('signupForm.other'),
  },
];
