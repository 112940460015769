import React from 'react';
import { Card } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import styles from './ProductCard.module.css';
import { Button } from '@mui/material';

type Props = {
  licencePeriod: string;
  product: any;
  handleProductSelection?: (product: any) => void;
};
const ProductCard = (props: Props) => {
  const location = useLocation();

  const totalPrice =
    props.licencePeriod === 'Monatlich'
      ? props.product.pricemonthlynetto.replace(/\./g, ',')
      : props.product.priceyearlynetto.replace(/\./g, ',');

  return (
    <>
      {props.product ? (
        <Card
          className={
            styles['selectionpage-productcard__base'] +
            ` ${
              props.product.id === 2 ? styles['productcard__recomended'] : ''
            }`
          }
          id={props.product.id + '-card'}
        >
          <Card.Header className={styles.productCardHeader}>
            {props.product.id === 2 ? (
              <div
                className={
                  styles.productCardRecommended + ' ' + styles.rotated45degNeg
                }
              >
                Empfehlung
              </div>
            ) : (
              <></>
            )}
            <Card.Title className={styles['product-title']}>
              {props.product.name}
            </Card.Title>
          </Card.Header>
          <Card.Body className={styles.productCardBody}>
            {props.licencePeriod === 'Jährlich' ? (
              props.product.name !== 'Kostenfreies Paket' ? (
                <span className={styles.productCardHeaderStrike}>
                  <s>{props.product.pricemonthlynetto.replace(/\./g, ',')}€</s>
                </span>
              ) : (
                <div className="margintop-20"></div>
              )
            ) : (
              <></>
            )}

            <div className={styles.moneyDiv}>
              <span className={styles['product-card-header']}>
                {totalPrice}€
              </span>
              <span>Pro Monat & Nutzer:in</span>
              {props.licencePeriod === 'Jährlich' ? (
                props.product.name !== 'Kostenfreies Paket' ? (
                  <>
                    <span>bei jährlich Abrechnung</span>
                    <span>
                      (
                      {(
                        parseFloat(totalPrice.replace(',', '.')) * 12
                      ).toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                        minimumFractionDigits: 2,
                      })}
                      ) zzgl. USt.
                    </span>
                  </>
                ) : (
                  <>
                    <span>bei jährlich Abrechnung</span>
                    <div className="marginbottom-24"></div>
                  </>
                )
              ) : (
                <span>
                  bei monatlicher Abrechnung
                  {props.product.name !== 'Kostenfreies Paket' && (
                    <>
                      <br />
                      zzgl. USt.
                    </>
                  )}
                </span>
              )}
            </div>
            <ul
              className={styles['product-feature-list'] + ' mt-4'}
              style={{ height: 'fit-content' }}
            >
              {props.product.features.map(
                (
                  feature: { description: string; availability: boolean },
                  i: number
                ) => (
                  // <li>✓ {feature}</li>
                  <li key={`${feature.description}-${i}`}>
                    <div className={styles['product-feature-list__item']}>
                      <span>{feature.availability ? '✓' : 'X'}</span>
                      <span>{feature.description}</span>
                    </div>
                  </li>
                )
              )}
            </ul>

            {location.pathname.includes('/summ-products') && (
              <Button
                variant="contained"
                id={`${props?.product?.name
                  ?.toLowerCase()
                  .split(' ')
                  .join('-')}`}
                className={styles.productCardSelect}
                onClick={() => {
                  props.handleProductSelection &&
                    props.handleProductSelection(props.product);
                }}
                style={{
                  marginTop: '5em',
                  textTransform: 'capitalize',
                }}
              >
                Auswählen
              </Button>
            )}
          </Card.Body>
        </Card>
      ) : (
        <Card>
          <Card.Body>
            <Card.Title>Kein Produkt gewählt</Card.Title>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default ProductCard;
