import { VisibilityOff, Visibility } from '@mui/icons-material';
import {
  InputAdornment,
  IconButton,
  FormControl,
  TextField,
  useTheme,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  name: string;
  style?: object;
  size?: 'small' | 'medium';
  error?: boolean;
  errorMsg?: string;
  id?: string;
};

const PasswordInput = ({
  value,
  handleChange,
  label,
  name,
  style = {},
  size = 'medium',
  error = false,
  errorMsg,
  id,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation('A11y');
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <FormControl
      sx={{ m: 1, ...style }}
      variant="outlined"
    >
      <TextField
        id={id}
        size={size}
        type={showPassword ? 'text' : 'password'}
        onChange={handleChange}
        name={name}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label={t('toggle_password_visibility')}
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label={label}
        value={value}
        error={error}
        helperText={errorMsg}
        sx={{
          '& .MuiFormHelperText-root': {
            color: theme.palette.error.main,
          },
          '& .MuiInputLabel-root': {
            color: `${theme.palette.primary.main} !important`,
          },
        }}
      />
    </FormControl>
  );
};

export default PasswordInput;
