import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material';

export interface MenuOptions {
  value: string;
  text: string;
  id: string;
  icon?: React.ReactNode;
  blockName?: string;
  handleClickMethod?: () => void;
}

type Props = {
  value: string | undefined;
  options: MenuOptions[];
  handleClick: (value: string) => void;
  label: string;
  classname?: string;
  classnameItem?: string;
  selectStyle?: object;
  itemStyle?: object;
  disabled?: boolean;
};

const DropdownMenuComp = ({
  value,
  options,
  handleClick,
  label,
  classname = '',
  classnameItem = '',
  selectStyle = {},
  itemStyle = {},
  disabled = false,
}: Props) => {
  const theme = useTheme();
  const handleChange = (event: SelectChangeEvent) => {
    handleClick(event?.target.value);
  };
  return (
    <FormControl
      sx={{ width: 'fit-content !important', minWidth: 'auto' }}
      size="small"
    >
      <Select
        value={value}
        onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        aria-label={label}
        className={classname}
        sx={{ ...selectStyle }}
        disabled={disabled}
      >
        {options.map(({ value, text, id, icon }) => (
          <MenuItem
            key={id}
            value={value}
            sx={{
              '&.Mui-selected': {
                backgroundColor: `${theme.palette.secondary.main} !important`,
              },
            }}
          >
            <ListItemText
              sx={{
                overflow: 'hidden',

                ...itemStyle,
              }}
              className={classnameItem}
            >
              {text}
            </ListItemText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropdownMenuComp;
