import {
  // GridCsvGetRowsToExportParams,
  GridToolbarContainer,
  // GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
// import { useSelector, shallowEqual } from 'react-redux';
// import { HistorySelector } from 'src/redux/translationHistory/selector';

type Props = {};

const CustomeToolbar = (props: Props) => {
  // const jobsList = useSelector(
  //   (state: any) => HistorySelector(state).jobsList,
  //   shallowEqual
  // );
  return (
    <GridToolbarContainer sx={{ mt: '0.5em' }}>
      {/* <GridToolbarExport
        csvOptions={{
          fileName: 'Erklaerungen',
          getRowsToExport: (params: GridCsvGetRowsToExportParams) => {
            return Object.values(jobsList).map(
              (item: { id: number }) => item.id
            );
          },
        }}
      /> */}
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
};

export default CustomeToolbar;
