import { Dispatch, Ref, SetStateAction, useRef, useState } from 'react';
import { FeedbackText, negativeFeedbackOpt } from '../../fixedData';
import ShowNefFeedbackInputComp from './ShowNefFeedbackInputComp';
import { useTranslation } from 'react-i18next';
import ConfirmModelComp from 'src/coreUI/confirmModelComp/ConfirmModelComp';
import MaterialSelectComp from 'src/coreUI/materialSelect/MaterialSelectComp';
import { SelectChangeEvent } from '@mui/material';

type Props = {
  showFeedbackModal: boolean;
  setShowFeedbackModal: Dispatch<SetStateAction<boolean>>;
  negFeedback: string;
  setNegFeedback: Dispatch<SetStateAction<string>>;
  updateSimpleTextUserEdited: (
    value: FeedbackText,
    userFeedbackText?: string
  ) => void;
};

const ShowFeedbackModal = (props: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  const [showNegFeedbackInput, setShowNegFeedbackInput] =
    useState<boolean>(false); // state that controls visibility of text area : used to show text area only if user selectes Andere option
  const [disableFeedbackSubmit, setDisableFeedbackSubmit] =
    useState<boolean>(false);
  const negFeedbackText: Ref<any> = useRef(); // ref to negative feedback text area
  const onChangeNegFeedback = (e: SelectChangeEvent<string>) => {
    setDisableFeedbackSubmit(e.target.value === 'other');
    setShowNegFeedbackInput(e.target.value === 'other');
    props.setNegFeedback(e.target.value);
  };
  return (
    <ConfirmModelComp
      open={props.showFeedbackModal}
      setOpen={props.setShowFeedbackModal}
      msg={t('feedback.negative.reasonQuestion')}
      disableConfirm={disableFeedbackSubmit}
      component={
        <>
          <MaterialSelectComp
            menuItem={negativeFeedbackOpt(t)}
            handleChange={onChangeNegFeedback}
            value={props.negFeedback}
            formStyle={{ mt: 0, width: '100%' }}
            selectSize={'small'}
            style={{
              width: '100%',
            }}
          />
          {showNegFeedbackInput === true && (
            <ShowNefFeedbackInputComp
              setDisableFeedbackSubmit={setDisableFeedbackSubmit}
              negFeedbackText={negFeedbackText}
            />
          )}
        </>
      }
      handleConfirm={() => {
        let feedback;
        if (props.negFeedback === 'other') {
          feedback = negFeedbackText?.current.value;
        } else {
          feedback = props.negFeedback;
        }
        props.updateSimpleTextUserEdited('negative', feedback);
        props.setShowFeedbackModal(false);
      }}
      closeButtonText={t('close', { ns: 'common' })}
      confirmText={t('submit', { ns: 'common' })}
    />
  );
};

export default ShowFeedbackModal;
