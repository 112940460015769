import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, useTheme } from '@mui/material';

type Props = {
  loading: boolean;
  children: any;
  size?: string;
};

const LoadingOverlay = ({ loading, children, size = '20px' }: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        margin: 'auto',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      {loading && (
        <CircularProgress
          size={size}
          sx={{
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
            position: 'absolute',
            zIndex: 2,
            color: theme.palette.primary.main,
          }}
          aria-label={'loading'}
        />
      )}

      {children}
    </Box>
  );
};

export default LoadingOverlay;
