import Grid from '@mui/material/Grid';
import { Image, CheckLg, XLg } from 'react-bootstrap-icons';
import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UploadImageSelector } from '../../redux/imageUpload/selector';
import React from 'react';

type Props = {
  file: any;
};

const FileUploading = ({ file }: Props) => {
  const { t } = useTranslation('easy_lang');
  const theme = useTheme();
  const uploadedImageList = useSelector(
    (state: any) => UploadImageSelector(state).uploadedImageList,
    shallowEqual
  );
  return (
    <Grid
      item
      container
      xs={12}
      justifyContent={'space-around'}
      display={'flex'}
      sx={{
        background: theme.palette.secondary.main,
        padding: '3px 4px 3px 12px',
        borderRadius: 1,
        mb: '.5em',
      }}
    >
      <Grid
        item
        xs={1.5}
        sx={{
          display: 'flex',
          margin: 'auto',
        }}
      >
        <Image
          size={'1.7em'}
          color={theme.palette.primary.main}
        />
      </Grid>
      <Grid
        item
        container
        xs={9}
        textAlign={'left'}
      >
        <Grid
          item
          xs={12}
          sx={{ color: theme.palette.primary.main, fontSize: '.8rem' }}
        >
          {file.name.split('.')[0].substring(0, 15)}
        </Grid>
        <Grid
          item
          xs={12}
        >
          {!uploadedImageList[file.name] && (
            <LinearProgress
              sx={{
                background: theme.palette.secondary.main,
                '& .MuiLinearProgress-bar': {
                  background: theme.palette.primary.main,
                },
              }}
            />
          )}
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ color: theme.palette.general.cyanBlue, fontSize: '0.8rem' }}
        >
          {uploadedImageList[file.name]
            ? t(uploadedImageList[file.name].msg)
            : t('uploading')}
        </Grid>
      </Grid>
      <Grid
        item
        xs={1.5}
        sx={{
          display: 'flex',
          margin: 'auto',
        }}
      >
        {uploadedImageList[file.name] &&
          (uploadedImageList[file.name].status === 'error' ? (
            <XLg
              size={'1em'}
              color={theme.palette.error.main}
            />
          ) : (
            <CheckLg
              size={'1em'}
              color={theme.palette.primary.main}
            />
          ))}
      </Grid>
    </Grid>
  );
};

export default FileUploading;
