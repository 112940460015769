import RequestReviewForm from '../Forms/RequestReviewForm';
import { useTranslation } from 'react-i18next';
import ConfirmModelComp from 'src/coreUI/confirmModelComp/ConfirmModelComp';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { userReview } from 'src/redux/user/actions';
import { useEffect, useState } from 'react';
import { supabaseSelector } from 'src/redux/supabase/selector';
import { isEmpty } from 'lodash';

type Props = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  close: () => void;
};
const RequestReviewPopup = ({ show, close, setShow }: Props) => {
  const { t } = useTranslation('common');
  const dispatch: any = useDispatch();

  const supabaseUser = useSelector(
    (state: any) => supabaseSelector(state).user,
    shallowEqual
  );

  const [reviewData, setReviewData] = useState({
    length: '',
    target: '',
    email: supabaseUser?.email || '',
    terms: false,
  });
  const { email, target, length, terms } = reviewData;

  useEffect(() => {
    if (supabaseUser?.email) {
      setReviewData({
        ...reviewData,
        email: supabaseUser.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supabaseUser]);

  const handleChange = (e: any) => {
    setReviewData({
      ...reviewData,
      [e.target.name]: e.target.value,
    });
  };
  const handleConfirm = () => {
    dispatch(userReview(reviewData));
  };

  return (
    <>
      <ConfirmModelComp
        open={show}
        setOpen={setShow}
        msg={t('requestReviewModal.title')}
        handleConfirm={handleConfirm}
        confirmText={t('requestReviewModal.submitText')}
        closeButtonText={t('modelClose')}
        confirmButtonStyle={{ width: 'fit-content' }}
        component={
          <RequestReviewForm
            reviewData={reviewData}
            handleChange={handleChange}
          />
        }
        disableConfirm={
          isEmpty(length) || isEmpty(target) || isEmpty(email) || !terms
        }
      />
    </>
  );
};

export default RequestReviewPopup;
