import './GlossaryField.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import { Grid, Link, Tooltip, Typography, useTheme } from '@mui/material';
import GlossaryItemView from './GlossaryItemView';
import { GlossaryData } from './glossary.types';
import { User } from './common.types';
import CopyButtomComp from '../../coreUI/copyButtonComp/CopyButtomComp';
import React, { useMemo } from 'react';

type WordEntry = {
  word: string;
  explanation: string;
  copyRestriction: number;
  link_to_source: string;
  licensename: string;
  licenselink: string;
  source: string;
};

type GeneralGlossaryListItemProps = {
  wordEntry: WordEntry;
  showAddIcon?: boolean;
  firstItem?: boolean;
  lastItem?: boolean;
  onlyItem?: boolean;
  addGlossaryWord: string;
  supabaseUser: User | null;
  createNewGlossary: (value: GlossaryData) => void;
  copiedText?: string;
  copyText?: string;
};

const GeneralGlossaryListItem = ({
  wordEntry,
  showAddIcon = false,
  firstItem = false,
  lastItem = false,
  onlyItem = false,
  addGlossaryWord,
  supabaseUser,
  createNewGlossary,
  copiedText,
  copyText,
}: GeneralGlossaryListItemProps) => {
  const theme = useTheme();

  const renderSource = () => {
    let link_to_source = null;
    if (wordEntry.link_to_source && wordEntry.source) {
      link_to_source = (
        <Link
          href={wordEntry.link_to_source}
          target="_blank"
          rel="noreferrer"
          sx={{
            cursor: 'pointer',
          }}
        >
          {wordEntry.source}
        </Link>
      );
    }

    let link_to_license = null;
    if (wordEntry.licenselink && wordEntry.licensename) {
      link_to_license = (
        <Link
          href={wordEntry.licenselink}
          target="_blank"
          sx={{
            cursor: 'pointer',
          }}
        >
          {wordEntry.licensename}
        </Link>
      );
    }

    if (link_to_source && link_to_license) {
      return `Quelle ${link_to_source} (${link_to_license})`;
    } else if (link_to_source) {
      return `Quelle ${link_to_source}`;
    }
  };

  const handleAddGlossary = () => {
    const jsonData: GlossaryData = {
      newentry: {
        word: wordEntry.word,
        explanation: wordEntry.explanation,
        link_to_source: supabaseUser!.email,
        source: supabaseUser!.email,
        tags: '',
      },
    };
    createNewGlossary(jsonData);
  };

  const copyButtonRender = useMemo(
    () => (
      <Grid
        item
        xs="auto"
      >
        <CopyButtomComp
          value={wordEntry.explanation}
          placement={'top'}
          size={'small'}
          buttonStyle={{
            position: 'relative',
          }}
          copiedText={copiedText}
          copyText={copyText}
        />
      </Grid>
    ),
    [copiedText, copyText, wordEntry.explanation]
  );

  return (
    <GlossaryItemView
      id="glossary_entry_head"
      firstItem={firstItem}
      lastItem={lastItem}
      onlyItem={onlyItem}
      accordionSummary={
        wordEntry.copyRestriction === 1 ? wordEntry.word + '*' : wordEntry.word
      }
      accordionDetails={
        <Grid
          item
          container
          xs={12}
        >
          <Grid
            item
            xs={12}
            id="glossary_entry_explanation"
          >
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                flexShrink: 0,
                color: theme.palette.primary.main,
              }}
            >
              {wordEntry.explanation}
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sx={{
              justifyContent: 'space-between',
            }}
          >
            <Grid
              item
              xs="auto"
            >
              {renderSource()}
            </Grid>
            {copyButtonRender}
          </Grid>
        </Grid>
      }
      expandIcon={
        showAddIcon ? (
          <Tooltip
            placement="top"
            title={addGlossaryWord}
          >
            <IconButton
              aria-label="add "
              disableFocusRipple
              disableRipple
              disableTouchRipple
              sx={{
                width: 'auto !important',
              }}
              onClick={handleAddGlossary}
              id="add_glossary_ai"
            >
              <AddCircleIcon
                sx={{ color: theme.palette.primary.main }}
                fontSize="small"
              />
            </IconButton>
          </Tooltip>
        ) : undefined
      }
      detailsStyle={{
        padding: '8px',
      }}
    />
  );
};

export default GeneralGlossaryListItem;
