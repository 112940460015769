import React, { Dispatch, SetStateAction } from 'react';
import { Form } from 'react-bootstrap';
import { Pencil } from 'react-bootstrap-icons';
import { FeedbackText } from '../../fixedData';
import { useTranslation } from 'react-i18next';
import { Button, useTheme } from '@mui/material';

type Props = {
  updateSimpleTextUserEdited: (value: FeedbackText) => void;
  setShowTooltipOutput: (value: boolean) => void;
  setShowFeedbackButtons: (value: boolean) => void;
  setShowSubmitAdjustedButton: Dispatch<SetStateAction<boolean>>;
};

const AdjustButtonComp = ({
  updateSimpleTextUserEdited,
  setShowTooltipOutput,
  setShowFeedbackButtons,
  setShowSubmitAdjustedButton,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  return (
    <Form.Group>
      <Button
        className="mt-2 w-100"
        onClick={() => {
          updateSimpleTextUserEdited('adjusted');
          setShowTooltipOutput(false);
        }}
        sx={{
          background: theme.palette.general.goldenYellow,
          color: theme.palette.primary.main,
          textTransform: 'Capitalize',
          '&:hover': {
            background: theme.palette.general.goldenYellow,
            color: theme.palette.primary.main,
          },
        }}
      >
        {t('feedback.edit.submitSuggestion')} <Pencil />
      </Button>
      <Button
        className="mt-2 w-100"
        onClick={() => {
          setShowSubmitAdjustedButton(false);
          setShowFeedbackButtons(true);
          setShowTooltipOutput(false);
        }}
        sx={{
          background: theme.palette.general.goldenYellow,
          color: theme.palette.primary.main,
          textTransform: 'Capitalize',
          '&:hover': {
            background: theme.palette.general.goldenYellow,
            color: theme.palette.primary.main,
          },
        }}
      >
        {t('back', { ns: 'common' })}
      </Button>
    </Form.Group>
  );
};

export default AdjustButtonComp;
