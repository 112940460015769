import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmModelComp from '../../coreUI/confirmModelComp/ConfirmModelComp';
import { useTheme } from '@mui/material';

type Props = {
  glossaryId: string;
  wordToDelete: string;
  deleteConfirm1Word: string;
  deleteConfirm2Word: string;
  yesDeleteWord: string;
  noCancelWord?: string;
  deleteGlossary: (id: string) => void;
  confirmButtonText?: string;
  switchValue: boolean;
};
const DeleteButton = ({
  glossaryId,
  wordToDelete,
  deleteConfirm1Word,
  deleteConfirm2Word,
  yesDeleteWord,
  noCancelWord,
  deleteGlossary,
  confirmButtonText,
  switchValue = true,
}: Props) => {
  const theme = useTheme();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const deleteEntry = async () => {
    deleteGlossary(glossaryId);
    setShowDeleteConfirmation(false);
  };

  return (
    <>
      <DeleteIcon
        sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
        fontSize="small"
        onClick={() => setShowDeleteConfirmation(true)}
      />
      {/* </IconButton> */}

      <ConfirmModelComp
        open={showDeleteConfirmation}
        setOpen={setShowDeleteConfirmation}
        msg={`${deleteConfirm1Word} “${wordToDelete
          .replace(/\([0-9]+\)$/, '')
          .trim()}” ${deleteConfirm2Word}`}
        handleConfirm={deleteEntry}
        confirmText={deleteConfirm2Word}
        confirmButtonText={confirmButtonText}
        closeButtonText={noCancelWord}
        confirmButtonStyle={{ width: 'fit-content' }}
        switchValue={switchValue}
      />
    </>
  );
};

export default DeleteButton;
