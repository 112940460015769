import { PersonCircle } from 'react-bootstrap-icons';
import theme from '../../theme/themeConfig';
// import { useTranslation } from 'react-i18next';

const UserIcon = () => {
  // const { t } = useTranslation('A11y');
  return (
    // <Link to="">
    <PersonCircle
      size={35}
      color={theme.palette.primary.main}
    />
    // </Link>
  );
};

export default UserIcon;
