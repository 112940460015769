import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { logoutAction } from 'src/redux/supabase/actions';
import Button from '@mui/material/Button/Button';
import { useTheme } from '@mui/material';

const SignoutButton = () => {
  const history = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation('common');
  const dispatch: any = useDispatch();
  const handleLogout = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    try {
      await dispatch(logoutAction());
      history('/');
    } catch {
      console.log('lgoout error');
    }
  };

  return (
    <Button
      variant="contained"
      className="w-50 text-center"
      type="submit"
      disableElevation
      onClick={handleLogout}
      sx={{
        textTransform: 'capitalize',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        p: '.6em',
        minWidth: '44px',
        '&:hover': {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.primary.main,
        },
      }}
    >
      {t('logout')}
    </Button>
  );
};

export default SignoutButton;
