import { SelectChangeEvent, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from '../translationOutput/TranslationOutput.module.css';
import { appMessageHandling } from 'src/utils/errorHandler';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { languageType, newLanguage, transLangVal } from '../fixedData';
import SettingComp from '@summ-ai-github/summ-frontend-package/components/outputTextSettingComp';
import { useContext, useCallback } from 'react';
import {
  emptyStateValueTrans,
  setTranslationConfig,
  updateTranlationText,
} from 'src/redux/translation/actions';
import { TranslateSelector } from 'src/redux/translation/selector';
import { TranslationOutputContext } from '../translationOutput/TranslationOutput';
import { languagesDetect } from './langDetect';
import { UserSelector } from 'src/redux/user/selector';

type Props = {};

const SettingCompWrapper = (props: Props) => {
  const { t } = useTranslation(['easy_language', 'A11y'], {
    nsMode: 'fallback',
  });
  const theme = useTheme();
  const dispatch: any = useDispatch();
  const { outputLanguage }: { outputLanguage: 'easy' | 'plain' } = useContext(
    TranslationOutputContext
  );
  const showFirstTimeUpdateSnackbar = useCallback(() => {
    if (localStorage.getItem('hasChangedPreference') !== 'true') {
      appMessageHandling(dispatch, t('setting.preferenceUpdated'), 'default');
      localStorage.setItem('hasChangedPreference', 'true');
    }
  }, [dispatch, t]);

  const transConfig = useSelector(
    (state: any) => TranslateSelector(state).transConfig,
    shallowEqual
  );

  const transjob = useSelector(
    (state: any) => TranslateSelector(state).transjob,
    shallowEqual
  );

  const autoLangDetect = useSelector(
    (state: any) => TranslateSelector(state).autoLangDetect,
    shallowEqual
  );

  const userDetails = useSelector(
    (state: any) => UserSelector(state).userDetails,
    shallowEqual
  );

  const handleSeparatorChange = useCallback(
    (e: SelectChangeEvent<any>) => {
      dispatch(
        updateTranlationText({
          input_text: transjob.simpleText || '',
          [`preferred_style_${outputLanguage}`]: e?.target.value,
        })
      );
    },
    [dispatch, outputLanguage, transjob.simpleText]
  );

  const handleNewlineChange = useCallback(
    (e: SelectChangeEvent) => {
      dispatch(
        setTranslationConfig({
          [`preferred_new_lines_${outputLanguage}`]: e?.target.value,
        })
      );
    },
    [dispatch, outputLanguage]
  );

  return (
    <SettingComp
      showFirstTimeUpdateSnackbar={() => showFirstTimeUpdateSnackbar()}
      settingTitle={`${t('setting.title')} (${t(
        `output.${userDetails?.preferred_output_language_type}Lang`
      )})`}
      settingDescrip={t('setting.description')}
      separatorType={languageType(t)}
      separatorLabel={t('toolbox.separator.selectSeparator')}
      toolTipsTitle={
        outputLanguage === 'plain' ? t('input.seperateMessage') : ''
      }
      outputLanguage={outputLanguage}
      handleSetSeparatorStyle={(e: SelectChangeEvent<any>) =>
        handleSeparatorChange(e)
      }
      separateVal={
        userDetails ? userDetails[`preferred_style_${outputLanguage}`] : 'none'
      }
      transLangMenu={transLangVal(
        t,
        autoLangDetect.value ? languagesDetect[autoLangDetect.value] : null
      )}
      transLangLabel={t('toolbox.transLang.select')}
      handelSetTransLang={(e: SelectChangeEvent<string>) => {
        dispatch(
          setTranslationConfig({
            [`preferred_translation_language_${outputLanguage}`]:
              e?.target.value,
          })
        );

        dispatch(
          emptyStateValueTrans({ autoLangDetect: { text: null, value: null } })
        );
      }}
      transLangVal={`${
        transConfig?.preferred_translation_language?.[
          `preferred_trans_language_${outputLanguage}`
        ] ?? 'de'
      }`}
      newLineLanguage={newLanguage(t)}
      newLineLabel={t('toolbox.newLine.newLineSelect')}
      newLineVal={
        transConfig?.[`preferred_new_lines_${outputLanguage}`] ?? 'True'
      }
      handelSetNewLine={handleNewlineChange}
      containerClassName={styles['setting-container']}
      titleStyle={{
        color: theme.palette.primary.main,
      }}
      descrStyle={{
        color: theme.palette.primary.main,
      }}
    />
  );
};

export default SettingCompWrapper;
