import {
  SeparateStyle,
  TranslateLang,
  UpdateTransJob,
  transConfigGet,
} from 'src/types/translation.types';
import http from '../config/httpComman';

export default class translationService {
  static async updateTranslationJob(
    jobid: string | number,
    data: UpdateTransJob
  ) {
    return await http.put(`job/web/${jobid}/`, data);
  }

  static async transaltionLag(data: TranslateLang) {
    return await http.post(`job/web/`, data);
  }

  static async separateStyle(data: SeparateStyle) {
    return await http.post(`job/switch-separator-style/`, data);
  }

  static async getTransConfig() {
    return await http.get(`job/translation-config/`);
  }

  static async updateTransConfig(data: transConfigGet) {
    return await http.put(`job/translation-config/`, data);
  }

  static async updateTextOutput(data: {
    input_text: string;
    [key: string]: string;
  }) {
    return await http.post(`job/convert-to-separator/`, data);
  }

  static async autoLangDetect(data: string) {
    return await http.post('translate/detect-language/', { text: data });
  }

  static async fileExtractTextApi(data: { file: File }) {
    http.defaults.headers['Content-Type'] = 'multipart/form-data';
    return await http.post('translate/txtfromfile/', data);
  }
}
