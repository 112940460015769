import { Button, Grid, Typography } from '@mui/material';
import theme from '../../theme/themeConfig';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { ChevronDown } from 'react-bootstrap-icons';

type Props = {
  contentCreator: (props: any) => any;
  title: string;
  expandTitle: string;
  moreTitle: string;
  onMoreAction?: () => any;
};

const ImageSectionPanel = (props: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Box>
      <Grid
        xs={12}
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{
          pb: 1.5,
        }}
      >
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.general.white,
              pb: 1,
            }}
          >
            {props.title}
          </Typography>
        </Grid>

        <Grid item>
          {isExpanded ? (
            <IconButton
              onClick={() => setIsExpanded(false)}
              aria-label="close"
            >
              <CloseIcon sx={{color: theme.palette.general.white}}/>
            </IconButton>
          ) : (
            <Button
              onClick={() => setIsExpanded(true)}
              sx={{
                textTransform: 'none',
                fontSize: '1rem',
                p: '0.375rem 0.75rem',
                width: '100%',
                height: '100%',
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.general.goldenYellow,
                '&:hover': {
                  backgroundColor: theme.palette.general.goldenYellow,
                },
              }}
            >
              {props.expandTitle}
            </Button>
          )}
        </Grid>
      </Grid>
      <Box
        sx={{
          border: '2px solid',
          borderColor: theme.palette.general.mediumLightGray,
          borderRadius: '8px',
          pt: 2.5,
          px: 3,
          position: 'relative', // For absolute positioning of the button
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '200px', // Ensures space for layout
          width: '100%',
          pb: '1.6 rem',
        }}
      >
        {props.contentCreator({ isExpanded: isExpanded })}

         Extend Button
        <Button
          onClick= {props.onMoreAction}
          sx={{
            position: 'absolute',
            width: '12rem',
            height: '3rem',
            bottom: '-27px',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.general.white,
            fontSize: '14px',
            textTransform: 'none',
            padding: '0 0',
            border: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 3,
            zIndex: 2,
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
              boxShadow: 'none',
            },
          }}
        >
          <span
            style={{
              marginBottom: '-10px',
            }}
          >
            {props.moreTitle}
          </span>
          <ChevronDown
            style={{
              marginTop: '0.7rem',
            }}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default ImageSectionPanel;
