import { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import TranslationTypeFilter from './TranslationTypeFilter';
import RowToDisplay from './RowToDisplay';
import { getTransHistory } from 'src/redux/translationHistory/actions';
import ConfirmModel from './ConfirmModel';
import HistoryTable from './HistoryTable';
// import { HistorySelector } from 'src/redux/translationHistory/selector';
import PageTitle from 'src/coreUI/pageTitle/PageTitle';
import { configSelector } from 'src/redux/appSetting/selector';

const TranslationHistory = () => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  const theme = useTheme();
  const dispatch: any = useDispatch();

  const [entries, setEntries] = useState('10');
  const [deleteJob, setDeleteJob] = useState({ id: '' });
  const [deleteAlert, setDeleteAlert] = useState(false);

  // const filterValue = useSelector(
  //   (state: any) => HistorySelector(state).filters,
  //   shallowEqual
  // );

  const historyTransSetting = useSelector(
    (state: any) => configSelector(state).historyTransSetting.filters,
    shallowEqual
  );
  const fetchFilterUpdate = useCallback(() => {
    dispatch(
      getTransHistory({
        page: 1,
        page_size: Number(entries),
        ...historyTransSetting,
      })
    );
  }, [dispatch, entries, historyTransSetting]);
  useEffect(() => {
    let timeOut = setTimeout(() => {
      fetchFilterUpdate();
    }, 500);

    return () => clearTimeout(timeOut);
  }, [fetchFilterUpdate]);

  const onDeleteEntry = (row: any, alertTrigger: boolean) => {
    setDeleteJob(row);
    setDeleteAlert(alertTrigger);
  };

  return (
    <>
      <ConfirmModel
        id={deleteJob.id}
        deleteAlert={deleteAlert}
        setDeleteAlert={setDeleteAlert}
        onDeleteEntry={onDeleteEntry}
      />
      <style type="text/css">
        {`
          .feedback-cell.adjusted {
            background: ${theme.palette.general.teal} !important;
          },
      `}
      </style>

      <Container fluid>
        <PageTitle title={t('history.myPastTranslations')} />
        <div className="filterBar">
          <Box
            mb="1rem"
            display="flex"
            justifyContent={
              localStorage.getItem('i18nextLng') !== 'fr'
                ? 'space-between'
                : 'end'
            }
            alignItems="flex-end"
          >
            <TranslationTypeFilter />
            <RowToDisplay
              setEntries={setEntries}
              entries={entries}
            />
          </Box>
        </div>
        <Row>
          <Col md={{ span: 12 }}>
            <div
              style={{
                width: '100%',
                backgroundColor: theme.palette.general.white,
              }}
            >
              <HistoryTable onDeleteEntry={onDeleteEntry} />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TranslationHistory;
