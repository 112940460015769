import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { CloudUpload } from 'react-bootstrap-icons';
import styles from './style.module.css';
import { LoadingButton } from '@mui/lab';

export type LangWords = {
  dragUploadType: string;
  dragAndDropSplitterText: string;
  buttonUpload: string;
};

type Props = {
  isDragAccept: boolean;
  isDragReject: boolean;
  open: () => void;
  dragRef: any;
  langWords: LangWords;
  loading?: boolean;
  containerStyle?: object;
  fileButton?: object;
};

const DragDropComp = ({
  isDragAccept,
  isDragReject,
  open,
  dragRef,
  langWords,
  loading,
  containerStyle = {},
  fileButton = {},
}: Props) => {
  const theme = useTheme();
  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        justifyContent: 'center',
        background: theme.palette.general.white,
        padding: 2,
        borderWidth: 3,
        borderRadius: 1,
        borderColor: isDragAccept
          ? theme.palette.success.main
          : isDragReject
          ? theme.palette.error.main
          : theme.palette.general.white,
        borderStyle: isDragAccept || isDragReject ? 'dashed' : 'solid',
        ...containerStyle,
      }}
    >
      <Grid
        item
        container
        xs={12}
        sx={{ height: 'fit-content', mt: '4em' }}
      >
        <Grid
          item
          xs={12}
          mb={2}
        >
          <CloudUpload size={'3em'} />
        </Grid>
        <Grid
          item
          xs={12}
          mb={2}
        >
          <Typography
            variant="body2"
            gutterBottom
            sx={{
              whiteSpace: 'normal',
              fontSize: '1rem',
            }}
          >
            {langWords.dragUploadType}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          mb={2}
        >
          <div
            className={styles['separator']}
            style={{ width: '50%', margin: 'auto' }}
          >
            &nbsp;&nbsp;{langWords.dragAndDropSplitterText}&nbsp;&nbsp;
          </div>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <LoadingButton
            variant="contained"
            loading={loading}
            sx={{
              textTransform: 'none',
              backgroundColor: `${theme.palette.primary.main} !important`,
              color: theme.palette.secondary.main,
              width: 'fit-content',
              ...fileButton,
            }}
            onClick={open}
            fullWidth
            disableElevation
          >
            {langWords.buttonUpload}
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DragDropComp;
