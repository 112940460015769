import TextField from '@mui/material/TextField';
import React, { FC, useEffect, useRef, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Autocomplete, Grid, useTheme } from '@mui/material';
import { isEmpty } from 'lodash';

type SearchInputCompProps = {
  handleSubmit: (value: string) => void;
  loading: boolean;
  placeholder?: string;
  inputId?: string;
  buttonId?: string;
  searchWord: string;
  wrapperStyle?: object;
  text: string;
  setText: (value: string) => any;
  isVisible: boolean;
};

const ImagePageSearchInputComp: FC<SearchInputCompProps> = ({
  handleSubmit,
  loading,
  placeholder,
  inputId = '',
  buttonId = '',
  searchWord,
  wrapperStyle,
  text,
  setText,
  isVisible,
}) => {
  const theme = useTheme();
  const refInput: any = useRef(null);
  const handleChange = (
    event: React.SyntheticEvent,
    value: string,
    reason: 'input' | 'reset' | 'clear'
  ) => {
    setText(value);
  };

  useEffect(() => {
    if (!loading && !isEmpty(text)) {
      setTimeout(() => {
        refInput.current.querySelector('input')?.focus();
      }, 100);
    }
  }, [loading, text]);

  useEffect(() => {
    if (isVisible){
      setText(text)
    }
  }, [isVisible]);

  return (
    <Grid
      item
      container
      xs={12}
      justifyContent={'center'}
      sx={wrapperStyle}
    >
      <Grid
        item
        xs={12}
      >
        <Autocomplete
          id={inputId}
          freeSolo={true}
          ref={refInput}
          renderInput={(params) => (
            <TextField
              {...params}
              label={placeholder}
              size="small"
              rows={3}
              multiline={true}
              value={text}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${theme.palette.general.white} !important`,
                },
                '& .MuiInputLabel-root': {
                  color: `${theme.palette.general.white} !important`,
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: `${theme.palette.general.white} !important`,
                },
                '& .MuiInputBase-input': {
                  color: `${theme.palette.general.white} !important`,
                },
              }}
            />
          )}
          autoComplete={false}
          inputValue={text}
          defaultValue={''}
          options={[]}
          onInputChange={handleChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !isEmpty(text)) {
              handleSubmit(text);
            }
          }}
        />
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          mt: 3,
        }}
      >
        <LoadingButton
          aria-disabled={isEmpty(text)}
          size="small"
          onClick={() => handleSubmit(text)}
          loading={loading}
          disableElevation
          disableFocusRipple
          variant="contained"
          id={buttonId}
          sx={{
            textTransform: 'none',
            fontSize: '1rem',
            p: '0.375rem 0.75rem',
            width: '100%',
            height: '100%',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.general.goldenYellow,
            '&:hover': {
              backgroundColor: theme.palette.general.goldenYellow,
            },
            ...(!loading && {
              '&:disabled': {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.general.goldenYellow,
              },
            }),
          }}
          disabled={isEmpty(text)}
        >
          {searchWord}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default ImagePageSearchInputComp;
