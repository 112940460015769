import React from 'react';
import Switch from '@mui/material/Switch';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import { styled, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const CustomeSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  width: 75,
  paddingTop: '3px',
  borderRadius: '.4em',
  background: theme.palette.secondary.main,
  '& .MuiSwitch-switchBase': {
    background: theme.palette.general.grayishBlue,
    margin: 1,
    width: 'auto',
    height: 'auto',
    position: 'inherit',
    top: 3,
    paddingLeft: '2px',
    paddingRight: '2px',
    borderRadius: '3px',
    transition: '0.3s linear',
    '&:hover': {
      background: theme.palette.primary.main,
      transition: '0.3s linear',
    },
    '&.Mui-checked': {
      left: 14,
      background: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.primary.main,
        transition: '0.3s linear',
      },
    },
    '& .MuiSwitch-input': {
      top: 0,
      height: '100%',
      left: '-170%',
      width: '400%',
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 20,
    height: 20,
    margin: 2,
  },
}));

type Props = {
  handleSwitch: () => void;
  switchInput: 'on' | 'off';
  checked: boolean;
  disabled?: boolean;
  label?: string;
};
const SwitchComp = ({
  handleSwitch,
  checked,
  disabled = false,
  label,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'A11y'], {
    nsMode: 'fallback',
  });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSwitch();
  };
  return (
    <CustomeSwitch
      checked={checked}
      onChange={handleChange}
      disabled={disabled}
      id="cutome-switch"
      // aria-checked={t('format_switch')}
      inputProps={{ 'aria-label': t('format_switch') }}
      icon={
        <FormatColorTextIcon
          fontSize="small"
          color="primary"
          sx={{
            color: theme.palette.secondary.main,
          }}
        />
      }
      checkedIcon={
        <FormatColorTextIcon
          fontSize="small"
          className="checked-formatter"
          sx={{
            color: theme.palette.secondary.main,
          }}
        />
      }
      sx={{
        '& .MuiSwitch-track': {
          backgroundColor: 'transparent !important',
          '&:before, &:after': {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 20,
            height: 20,
          },
          '&:after': {
            content: `"${t('off')}"`,
            left: 12,
            fontSize: '0.8rem',
          },
          '&:before': {
            content: `"${t('on')}"`,
            right: 12,
            fontSize: '0.8rem',
          },
        },
      }}
    />
  );
};

export default SwitchComp;
