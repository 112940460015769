export const clearLocalSessionStorage = (element: string) => {
  sessionStorage.removeItem(element);
};

export const clearSessionandLocalStorge = () => {
  // store all locastorage key
  let allItems: string[] = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key) allItems.push(key);
  }
  allItems?.forEach((key) => {
    if (!['IN_PURCHASE_FLOW', 'i18nextLng', 'signup_form'].includes(key))
      localStorage.removeItem(key);
  });

  sessionStorage.clear();
};
