import { useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';
import React from 'react';

type Props = {
  id?: string;
  value: string;
  handleChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handlekeyUp?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  handlekeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  variant?: 'outlined' | 'filled' | undefined;
  label?: string;
  placeholder?: string;
  style?: object;
  endIcon?: React.ReactNode | null;
  disable?: boolean;
  name?: string;
  require?: boolean;
  error?: boolean;
  errorMsg?: string;
  type?: string;
  size?: 'small' | 'medium';
};

const InputComponent = ({
  id = 'outlined-basic',
  value,
  handleChange,
  variant = 'outlined',
  label,
  style = {},
  endIcon,
  disable = false,
  name,
  require,
  error,
  handlekeyUp,
  placeholder,
  handlekeyDown,
  type = 'text',
  size = 'small',
  errorMsg,
}: Props) => {
  const theme = useTheme();
  return (
    <TextField
      id={id}
      label={label}
      placeholder={placeholder}
      variant={variant}
      value={value}
      onChange={handleChange}
      sx={{
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: `${theme.palette.primary.main} !important`,
        },
        '& .MuiInputLabel-root': {
          color: `${theme.palette.primary.main} !important`,
        },
        ...style,
      }}
      size={size}
      InputProps={{
        endAdornment: endIcon,
      }}
      disabled={disable}
      name={name}
      required={require}
      error={error}
      helperText={errorMsg}
      onKeyUp={handlekeyUp}
      onKeyDownCapture={handlekeyDown}
      type={type}
    />
  );
};

export default InputComponent;
