import React from 'react';
import { useTheme } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useTranslation } from 'react-i18next';

const HelpButton = () => {
  const { t } = useTranslation('A11y');
  const theme = useTheme();
  return (
    <a
      href="https://summai.notion.site/FAQ-Hilfe-741eb93518944ce0b543e2d1b5aad0c2"
      target="_blank"
      rel="noreferrer"
      aria-label={t('help')}
    >
      <HelpOutlineIcon
        sx={{ fontSize: '3rem', color: theme.palette.primary.main }}
      />
    </a>
  );
};

export default HelpButton;
